import { call, put } from 'redux-saga/effects';
import { lsDataFetchingSucceed } from '../Actions/actionsExtra';
import { TSliceUI } from '../Slices/UI/sliceUI';

/**
 * Получения данных из локального хранилища
 */
export function* workerGetLocalStorage() {
  const lsDataObj: Partial<TSliceUI> | undefined = yield call(() => {
    const lsData = localStorage.getItem('UIAttributes');
    if (!lsData) return null;
    return JSON.parse(lsData);
  });
  yield put(lsDataFetchingSucceed(lsDataObj));
}
