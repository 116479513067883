import { schema } from 'normalizr';
import schemaNormalizeRoles from './schemaNormalizeRoles';
import schemaNormalizeAlerts from './schemaNormalizeAlerts';
import schemaNormalizeUnits from './schemaNormalizeUnits';

/**
 * Нормализация юзеров
 *
 * @param depth - глубина нормализации
 * @returns схема
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaNormalizeUsers = (depth?: number): schema.Entity => {
  return new schema.Entity('users', {
    role: schemaNormalizeRoles(),
    ownUnits: [
      depth ? schemaNormalizeUnits(depth - 1) : new schema.Entity('units'),
    ],
    membershipUnits: [
      depth ? schemaNormalizeUnits(depth - 1) : new schema.Entity('units'),
    ],
    alerts: [schemaNormalizeAlerts()],
  });
};

export default schemaNormalizeUsers;
