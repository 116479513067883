import { APP_INIT_QUEUE } from '../../../Settings/app';
import { TFetchStatus } from '../../../Types/typesGlobal';
import { store } from '../../Store';
import { TSliceData } from './sliceData';

/**
 * Получить статус приложения
 *
 * @param state Объект хранилища
 * @returns статус
 */
export const getAppStatus = (
  state?: TSliceData['fetchStatuses']
): TFetchStatus => {
  const statuses = state || store.getState().sliceData.fetchStatuses;
  const queue = APP_INIT_QUEUE.filter((item) => item.required).map(
    (item) => statuses[item.query.id]?.status
  );
  if (queue.includes('error')) return 'error';
  if (queue.includes('progress')) return 'progress';
  if (queue.length === queue.filter((q) => q === 'success').length)
    return 'success';
  return 'initial';
};
