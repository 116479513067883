/**
 * Actions constants
 */
export const APP_INITIAL_STATE = 'app/initial';
export const INITIAL_FETCH = 'initial/fetch';

export const ME_FETCH = 'me/fetch';

export const LS_DATA_FETCH = 'ls-data/fetch';
export const LS_DATA_FETCH_SUCCEED = 'ls-data/fetch-succeed';

export const DATA_FETCH = 'data/fetch';
export const DATA_FETCH_PENDING = 'data/fetch-pending';
export const DATA_FETCH_SUCCEED = 'data/fetch-succeed';
export const DATA_FETCH_REJECTED = 'data/fetch-rejected';
