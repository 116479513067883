/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext } from 'react';
import { CarouselMainContext } from './Helpers/Contexts';
import _ from 'lodash';
// import { CollectionContext } from '../CustomCollection/Helpers/Contexts';

export const CarouselContext: React.FC<{
  slideSets: number[];
  children: React.ReactNode;
}> = ({ children, slideSets }) => {
  return null;
  // const units = useContext(CollectionContext)?.units || [];

  // const slides = slideSets.map((slideSet) => _.chunk(units, slideSet));

  // return (
  //   <CarouselMainContext.Provider value={{ slides }}>
  //     {children}
  //   </CarouselMainContext.Provider>
  // );
};
