import { clearJWT } from '../../../Store/Slices/UI/sliceUI';
import { store } from '../../../Store/Store';

/**
 * выйти из профиля (разлогиниться)
 */
export const ActionLogOut = () => {
  store.dispatch(clearJWT());
  window.location.replace('/');
};
