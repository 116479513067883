import _ from 'lodash';
import { Mutator } from '../../../Library/Mutator/Mutator';
import { TResponseDataModel } from '../../../Types/typesFetch';
import { TSliceUI } from '../../Slices/UI/sliceUI';

/**
 * Сборка коллекций слайса интерфейса из объекта ответа по данным модели
 *
 * @param dataModel - объект ответа
 * @param mutator - мутатор
 * @returns - Коллекции или undefined
 */
export const constructUiCollections = (
  dataModel: TResponseDataModel['sliceUi'],
  mutator: Mutator
):
  | Pick<TSliceUI, 'jwt' | 'meID' | 'customStates' | 'attributesSave'>
  | undefined => {
  if (!dataModel) return;

  const { mutantDataModel } = mutator.applyObjectVariables({
    mutantDataModel: dataModel,
  });

  return _.isObject(mutantDataModel) && !_.isArray(mutantDataModel)
    ? (_.pick(mutantDataModel, [
        'jwt',
        'meID',
        'customStates',
        'attributesSave',
      ]) as Pick<TSliceUI, 'jwt' | 'meID' | 'customStates' | 'attributesSave'>)
    : undefined;
};
