import { ChangeEvent } from 'react';
import { TCustomInputImage } from '../types';
import { setInputValue } from './setInputValue';

export const ImageTransform = (
  name: string,
  eventFile: ProgressEvent<FileReader>,
  changeHandle: (e: ChangeEvent<any>) => void,
  width: number,
  height: number,
  crop: boolean,
  convertTo: TCustomInputImage['convertTo'] = 'jpeg',
  quality = 80,
  cropPosX = 0.5,
  cropPosY = 0.5,
  required?: boolean
) => {
  const newImage = document.createElement('img');
  newImage.src = eventFile.target?.result as string;
  newImage.onload = (eventImage: ProgressEvent<HTMLImageElement> | Event) => {
    const currentImage = eventImage.target as HTMLImageElement;
    if (!currentImage) return;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const imageScale = crop
      ? currentImage.width >= currentImage.height
        ? height / currentImage.height
        : width / currentImage.width
      : currentImage.width >= currentImage.height
      ? width / currentImage.width
      : height / currentImage.height;

    const canvasContext = canvas.getContext('2d');
    canvasContext?.drawImage(
      currentImage,
      (newImage.width - width / imageScale) * cropPosX,
      (newImage.height - height / imageScale) * cropPosY,
      width / imageScale,
      height / imageScale,
      0,
      0,
      width,
      height
    );
    const imageFileDataURLFromCanvas = canvasContext?.canvas.toDataURL(
      `image/${convertTo}`,
      quality / 100
    );
    if (imageFileDataURLFromCanvas) {
      setInputValue({
        id: name,
        name: name,
        type: 'text',
        required: !!required,
        value: imageFileDataURLFromCanvas,
        onchange: (e) => changeHandle(e as unknown as ChangeEvent<unknown>),
      });
    }
  };
};
