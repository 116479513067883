import { TTagMutations } from './types';

/* eslint-disable no-useless-escape */
export const VOID_ELEMENTS: string[] = [
  'area',
  'base',
  'br',
  'col',
  'embed',
  'hr',
  'img',
  'input',
  'link',
  'meta',
  'param',
  'source',
  'track',
  'wbr',

  'Card.Img',
  'FormControl',
  'Form.Control',
  'InputGroup.Checkbox',
  'Icon',
  'Template',
];

export const DOM_CREATOR_EXCLUDE_ATTRIBUTES: Record<string, string[]> = {
  CUSTOM: ['tag', 'mutationSchema', 'excluded', 'childrenPrototype', 'label'],
  DEFAULT: [
    'children',
    'tag',
    'excluded',
    'group',
    'query',
    'mutationSchema',
    'childrenPrototype',
    'label',
  ],
};

export const TAG_MUTATION: TTagMutations = {
  EXCLUDE: {
    VALUES: {
      all: [],
    },
    ATTRIBUTES: {
      all: [
        /mutationProps/,
        /children/,
        /tag/,
        /onSucceed/,
        /onRejected/,
        /responseModel/,
        /mutationSchema/,
      ],
    },
  },
  INCLUDE: {
    VALUES: {
      all: [],
    },
    ATTRIBUTES: {
      all: [],
    },
  },
};
