import { TSiteActionProps } from '../../../Types/typesGlobal';
import { Mutator } from '../../Mutator/Mutator';
import { Action } from '../Action';

/**
 * Ассинхронная функция выполнения массива экшенов
 * @param actions массив параметров экшенов
 * @param action экземпляр класса Action
 * @param mutator экземпляр класса Mutator
 */
export async function execMultiplyActions(
  actions: TSiteActionProps[],
  action: Action,
  mutator: Mutator
) {
  for (const props of actions) {
    await new Promise((resolve) => {
      action.exec(mutator.applyObjectVariables(props));
      resolve(null);
    });
  }
}
