import _ from 'lodash';
import { store } from '../../Store';
import {
  TAbstractStore,
  TAbstractStoreObject,
} from '../../../Types/typesGlobal';
import { Permissions } from '../../../Services/Permissions';

export const applyPermissions = (
  storageObj: TAbstractStore
): TAbstractStore => {
  const rootState = store.getState();
  const meID = rootState.sliceUI.meID;
  const me = meID ? rootState.sliceData.collections.users[meID] : undefined;

  const checkItem = (
    stItem: TAbstractStoreObject
  ): TAbstractStoreObject | undefined => {
    if (!stItem.objPerm) return stItem; // Выключить!!!
    const checkPerms = new Permissions(stItem, me);
    const perms = checkPerms.object();
    // console.log(checkPerms.isOwner());
    if (!perms.edit && !perms.view) {
      return { id: stItem.id, excluded: true } as TAbstractStoreObject;
    }
    return { ...stItem, disabled: false };
  };

  return _.reduce(
    storageObj,
    (r, v, k) => {
      const colChecked = _.reduce(
        v,
        (rr, vv, kk) => {
          const checked = vv && checkItem(vv);
          if (checked) return { ...rr, [kk]: checked };
          return { ...rr };
        },
        {} as { [id: string]: TAbstractStoreObject }
      );

      return { ...r, [k]: colChecked };
    },
    {} as TAbstractStore
  );
};
