import _ from 'lodash';
import {
  TBaseDataObject,
  TBasePermission,
  TBaseStructureObject,
} from '../Types/typesGlobal';
import { TUserNorm } from '../Types/typesUser';

type TPerm = { view: boolean; edit: boolean };

export class Permissions {
  private checkObject: TBaseStructureObject & TBaseDataObject;

  private user: TUserNorm | undefined;

  constructor(
    object: TBaseStructureObject & TBaseDataObject,
    user?: TUserNorm
  ) {
    this.checkObject = object;
    this.user = user;
  }

  private getArray(text: string) {
    return text.replace(' ', '').split(/;|,/);
  }

  private getUserPerm(perms: string) {
    return (
      this.getArray(perms).findIndex(
        (id) => id === this.user?.id || id === '0'
      ) !== -1
    );
  }

  private getGroupPerm(perms: string) {
    const userGroupIDs = [
      ...(this.user?.ownUnits || []),
      ...(this.user?.membershipUnits || []),
      '0',
    ];
    const permIDs = this.getArray(perms);
    return _.intersection(userGroupIDs, permIDs).length > 0;
  }

  private isOwner() {
    if (!this.user) return false;
    const unitIsOwner = !!(
      this.user?.ownUnits?.findIndex(
        (groupID) => this.checkObject.ownerUnit?.id === groupID
      ) !== -1
    );
    const personIsOwner = !!(this.checkObject.ownerPerson?.id === this.user.id);
    return unitIsOwner || personIsOwner;
  }

  private getPerms(perms: TBasePermission) {
    const perm = _.reduce(
      perms,
      (r, v, k) => {
        switch (k) {
          case 'usersView':
            return {
              ...r,
              users: {
                ...(r.users || {}),
                ...{ view: this.getUserPerm(v || '') },
              },
            };
          case 'usersEdit':
            return {
              ...r,
              users: {
                ...(r.users || {}),
                ...{ edit: this.getUserPerm(v || '') },
              },
            };
          case 'unitsView':
            return {
              ...r,
              units: {
                ...(r.units || {}),
                ...{ view: this.getGroupPerm(v || '') },
              },
            };
          case 'unitsEdit':
            return {
              ...r,
              units: {
                ...(r.units || {}),
                ...{ edit: this.getGroupPerm(v || '') },
              },
            };
          default:
            return { ...r };
        }
      },
      {} as { users: TPerm; units: TPerm }
    );

    return {
      view: perm.units.view || perm.users.view,
      edit: perm.units.edit || perm.users.edit,
    };
  }

  object(): TPerm {
    if (this.isOwner()) return { view: true, edit: true };
    if (!this.checkObject.objPerm) return { view: false, edit: false };
    const perms = this.getPerms(this.checkObject.objPerm);
    return perms;
  }

  field(fieldName: string): TPerm {
    if (this.isOwner()) return { view: true, edit: true };
    if (!this.checkObject.fieldPerm) return { view: false, edit: false };
    const fieldPerms = this.checkObject.fieldPerm?.find(
      (item) => item.field === fieldName
    );
    if (!fieldPerms) return this.object();
    return this.getPerms(fieldPerms);
  }
}
