import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { initialFetching } from './Store/Actions/actionsExtra';
import { getAppStatus } from './Store/Slices/Data/getAppStatus';
import { getCollectionItems } from './Store/Slices/Data/getCollectionItems';
import { RootState } from './Store/Store';
import { TSiteSettings } from './Types/typesStructure';
import { constructRoutes } from './Helpers/constructRoutes';

/**
 *  Компонент приложения
 *
 * @returns приложение
 */
const App: React.FC = () => {
  const collections = useSelector(
    (state: RootState) => state.sliceData.collections
  );
  const locale = useSelector((state: RootState) => state.sliceUI.locale);
  const dispatch = useDispatch();
  const appLoadingStatus = useSelector((state: RootState) =>
    getAppStatus(state.sliceData.fetchStatuses)
  );

  const siteSettings = useMemo(
    () =>
      getCollectionItems(
        'siteSets',
        { param: 'locale', value: locale },
        collections
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, collections.siteSets]
  )?.[0] as TSiteSettings | undefined;

  useEffect(() => {
    if (appLoadingStatus === 'initial') {
      dispatch(initialFetching());
    }
  }, [appLoadingStatus, dispatch, siteSettings]);

  const routes = useMemo(
    () =>
      constructRoutes(collections.sitePages, appLoadingStatus, siteSettings),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appLoadingStatus]
  );

  return <RouterProvider router={createBrowserRouter(routes)} />;
};

export default App;
