import { all } from 'redux-saga/effects';
import { sagaInitialWatch } from './sagaInitial';
import { sagaStoreItemsFetch } from './sagaStoreItemsFetch';

/**
 * Корневая сага
 */
export function* sagaRoot() {
  yield all([sagaInitialWatch(), sagaStoreItemsFetch()]);
}
