import { createAction } from '@reduxjs/toolkit';
import {
  APP_INITIAL_STATE,
  INITIAL_FETCH,
  LS_DATA_FETCH,
  LS_DATA_FETCH_SUCCEED,
  DATA_FETCH,
  DATA_FETCH_PENDING,
  DATA_FETCH_REJECTED,
  DATA_FETCH_SUCCEED,
} from '../../Settings/extraActions';
import { TBaseDataObject } from '../../Types/typesGlobal';
import { TSliceUI } from '../Slices/UI/sliceUI';
import {
  TSoreItemsFetchExtra,
  TStoreItemsFetchStatusExtra,
  TStoreItemsFetchingSucceedExtra,
} from '../../Types/typesFetch';

export const setInitialState = createAction(APP_INITIAL_STATE);
export const initialFetching = createAction(INITIAL_FETCH);

export const lsDataFetching = createAction<TBaseDataObject>(LS_DATA_FETCH);
export const lsDataFetchingSucceed = createAction<
  Partial<TSliceUI> | undefined
>(LS_DATA_FETCH_SUCCEED);

export const dataFetching = createAction<TSoreItemsFetchExtra>(DATA_FETCH);
export const dataFetchingPending =
  createAction<TStoreItemsFetchStatusExtra>(DATA_FETCH_PENDING);
export const dataFetchingRejected =
  createAction<TStoreItemsFetchStatusExtra>(DATA_FETCH_REJECTED);
export const dataFetchingSucceed =
  createAction<TStoreItemsFetchingSucceedExtra>(DATA_FETCH_SUCCEED);
