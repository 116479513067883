import _ from 'lodash';
import { TemplateElement } from '../TemplateElement';
import { TTemplateElem } from '../types';

/**
 * Компонент для отображения массива корневых элементов макета (верхнего уровня)
 *
 * @param elements - массив элементов макета
 * @param schema - схема изменения массива
 *
 * @returns React-компоненты элементов макета
 */
export const TemplateElements: React.FC<{
  elements: TTemplateElem['children'];
  schema?: TTemplateElem['mutationSchema'];
}> = ({ elements, schema }) => {
  if (!_.isObject(elements)) return null;
  return (
    <>
      {_.isArray(elements) ? (
        elements.map((templateItem, i) => {
          if (!_.isObject(templateItem)) return null;
          const newItem = {
            ...templateItem,
            mutationSchema: {
              ...(schema || templateItem.mutationSchema || {}),
              path: [...(schema?.path || []), `[${i}]`],
            },
          };
          return <TemplateElement key={i} {...newItem} />;
        })
      ) : (
        <TemplateElement
          {...{
            ...elements,
            mutationSchema: {
              ...(schema || elements.mutationSchema || {}),
            },
          }}
        />
      )}
    </>
  );
};
