import { TSitePage, TSiteSettings } from '../../../../Types/typesStructure';
import { useEffect, useMemo } from 'react';
import { Action } from '../../../../Library/Action/Action';
import { store } from '../../../../Store/Store';
import { SITE_TEMPLATE } from '../../../../Settings/template';
import { getCollectionItems } from '../../../../Store/Slices/Data/getCollectionItems';
import { useNavigate } from 'react-router-dom';
import { TemplateElements } from './Components/TemplateElement/Components/TemplateElements';

/**
 * Шаблон страниц.
 - На странице происходит редирект в случае, если он есть в sliceUI -> redirectTo.
 - При загрузке страницы запускаются action`ы.
 - При помощи функции {@link constructAlertBlocks} собираются блоки с уведомлениями.
 - Блоки со значением атрибута fetchStatus отличным от success, догружаются отдельно (повторяется при изменении объекта page).
 * @returns Компонент страницы
 */
export const Page: React.FC<TSitePage> = (page) => {
  const rootState = store.getState();
  const navigate = useNavigate();

  const siteSets = useMemo(
    () =>
      getCollectionItems(
        'siteSets',
        {
          param: 'locale',
          value: rootState.sliceUI.locale,
        },
        rootState.sliceData.collections
      )[0],
    [rootState.sliceData.collections, rootState.sliceUI.locale]
  ) as TSiteSettings | undefined;

  // Запускаю экшны при загрузке страницы
  useEffect(() => {
    if (page.onLoadActions?.length) {
      page.onLoadActions.forEach((actionProps) => {
        const action = new Action(navigate);
        action.exec(actionProps);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.onLoadActions]);

  return (
    <>
      <head>
        <TemplateElements
          elements={SITE_TEMPLATE.HEAD.CONSTANT_ITEMS}
          schema={{ path: ['staticHeadElements'] }}
        />
        <TemplateElements
          elements={siteSets?.headElements || SITE_TEMPLATE.HEAD.DEFAULT_ITEMS}
          schema={{
            path: [
              siteSets?.headElements ? 'headElements' : 'defaultHeadElements',
            ],
            attributes: siteSets?.headElements
              ? siteSets.mutationSchema?.attributes?.filter((item) =>
                  /^headElements\./.test(item)
                )
              : [],
          }}
        />
      </head>
      <body>
        <TemplateElements
          elements={SITE_TEMPLATE.BODY.CONSTANT_ITEMS}
          schema={{ path: ['staticBodyElements'] }}
        />
        <TemplateElements
          elements={page.template?.template || SITE_TEMPLATE.BODY.DEFAULT_ITEMS}
          schema={{
            ...page.template?.mutationSchema,
            path: [page.template ? 'template' : 'defaultBodyElements'],
          }}
        />
      </body>
    </>
  );
};
