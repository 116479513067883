import _ from 'lodash';
import { useContext } from 'react';
import { TemplateContext } from '../TemplateElement/Helpers/contexts';
import { TemplateElement } from '../TemplateElement/TemplateElement';
import { TCustomTemplate } from './types';
import { TTemplateContext, TTemplateElem } from '../TemplateElement/types';
import { useTemplate } from './helpaers/useTemplate';

/**
 * Компонент макета с дочерними элементами
 *
 * @param templateElement - объект макета
 * @returns - React-компоненты дочерних элементов
 */
export const CustomTemplate: React.FC<TCustomTemplate> = (templateElement) => {
  const template = useTemplate(templateElement.id, templateElement.name);
  const templateContext = useContext(TemplateContext);

  if (!template || template.excluded) return null;

  const oldDepth: TCustomTemplate['variablesDepth'] = _.reduce(
    templateContext?.variablesDepth,
    (r, v, k) => {
      if (_.isString(v) && v === 'all') return { ...r, [k]: v };
      if (!(v - 1)) return { ...r };
      return { ...r, [k]: v - 1 };
    },
    {}
  );

  const oldVariables: TCustomTemplate['variables'] = _.reduce(
    templateContext?.variables,
    (r, v, k) => {
      if (!oldDepth[k]) return { ...r };
      return { ...r, [k]: v };
    },
    {}
  );

  const { id, name, variables } = templateElement;
  const children = _.isArray(templateElement.prototype)
    ? (
        templateElement.prototype.filter((item) =>
          _.isObject(item)
        ) as TTemplateElem[]
      ).map((item, i) => ({
        ...item,
        mutationSchema: {
          ...template.mutationSchema,
          path: ['template', `[${i}]`],
        },
      }))
    : _.isObject(templateElement.prototype)
    ? [
        {
          ...templateElement.prototype,
          mutationSchema: {
            ...template.mutationSchema,
            path: ['template'],
          },
        },
      ]
    : [];

  const contextValue: TTemplateContext = {
    id,
    name,
    path: [...(templateContext?.path || []), id || name || 'unknown'],
    variables: _.merge(oldVariables, variables),
    variablesDepth: _.merge(oldDepth, templateElement.variablesDepth),
    children,
  };

  return (
    <TemplateContext.Provider value={contextValue}>
      {_.isArray(template.template) ? (
        template.template.map((item, i) => (
          <TemplateElement
            key={i}
            {...{
              ...item,
              mutationSchema: {
                ...template.mutationSchema,
                path: ['template', `[${i}]`],
              },
            }}
          />
        ))
      ) : (
        <TemplateElement
          {...{
            ...template.template,
            mutationSchema: {
              ...template.mutationSchema,
              path: ['template'],
            },
          }}
        />
      )}
    </TemplateContext.Provider>
  );
};
