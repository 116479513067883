import _ from 'lodash';
import { TSiteActionProps, TToast } from '../../../Types/typesGlobal';
import { store } from '../../../Store/Store';
import { setToast, switchShowToast } from '../../../Store/Slices/UI/sliceUI';
import { nanoid } from '@reduxjs/toolkit';
import { ActionDeleteToast } from '../ActionDeleteToast/ActionDeleteToast';

export const ActionShowToast = (arg: TSiteActionProps['argument']) => {
  if (_.has(arg, 'values') && _.isObject(_.get(arg, 'values'))) {
    const toast = {
      ...(arg as TToast),
      id: nanoid(4),
    };
    store.dispatch(setToast(toast));
    setTimeout(
      () => store.dispatch(switchShowToast(toast.id)),
      toast.showTimeout || 300
    );
    if (toast.timeout) {
      setTimeout(
        () =>
          ActionDeleteToast({
            id: toast.id,
            timeout: toast.showTimeout || 300,
          }),
        toast.timeout
      );
    }
  } else {
    console.log(
      'Check argument object: { timeOut?: number, values: TBaseDataObject }'
    );
  }
};
