import { TIcon } from '../types';

export const defaultIcon: TIcon = {
  id: 'default-icon',
  createdAt: '',
  updatedAt: '',
  publishedAt: '',
  locale: 'ru',
  name: 'error',
  normal:
    'M 12.5 17.5 C 12.783 17.5 13.021 17.404 13.212 17.212 C 13.404 17.02 13.5 16.783 13.5 16.5 C 13.5 16.216 13.404 15.979 13.212 15.787 C 13.021 15.595 12.783 15.5 12.5 15.5 C 12.217 15.5 11.979 15.595 11.787 15.787 C 11.596 15.979 11.5 16.216 11.5 16.5 C 11.5 16.783 11.596 17.02 11.787 17.212 C 11.979 17.404 12.217 17.5 12.5 17.5 Z M 12.5 13.5 C 12.783 13.5 13.021 13.404 13.212 13.212 C 13.404 13.02 13.5 12.783 13.5 12.5 L 13.5 8.5 C 13.5 8.216 13.404 7.979 13.212 7.787 C 13.021 7.595 12.783 7.5 12.5 7.5 C 12.217 7.5 11.979 7.595 11.787 7.787 C 11.596 7.979 11.5 8.216 11.5 8.5 L 11.5 12.5 C 11.5 12.783 11.596 13.02 11.787 13.212 C 11.979 13.404 12.217 13.5 12.5 13.5 Z M 12.5 22.5 C 11.117 22.5 9.817 22.237 8.6 21.712 C 7.383 21.187 6.325 20.475 5.425 19.575 C 4.525 18.675 3.812 17.616 3.287 16.4 C 2.762 15.183 2.5 13.883 2.5 12.5 C 2.5 11.116 2.762 9.816 3.287 8.6 C 3.812 7.383 4.525 6.325 5.425 5.425 C 6.325 4.525 7.383 3.812 8.6 3.287 C 9.817 2.762 11.117 2.5 12.5 2.5 C 13.883 2.5 15.183 2.762 16.4 3.287 C 17.617 3.812 18.675 4.525 19.575 5.425 C 20.475 6.325 21.187 7.383 21.712 8.6 C 22.237 9.816 22.5 11.116 22.5 12.5 C 22.5 13.883 22.237 15.183 21.712 16.4 C 21.187 17.616 20.475 18.675 19.575 19.575 C 18.675 20.475 17.617 21.187 16.4 21.712 C 15.183 22.237 13.883 22.5 12.5 22.5 Z M 12.5 20.5 C 14.733 20.5 16.625 19.725 18.175 18.175 C 19.725 16.625 20.5 14.733 20.5 12.5 C 20.5 10.266 19.725 8.375 18.175 6.825 C 16.625 5.275 14.733 4.5 12.5 4.5 C 10.267 4.5 8.375 5.275 6.825 6.825 C 5.275 8.375 4.5 10.266 4.5 12.5 C 4.5 14.733 5.275 16.625 6.825 18.175 C 8.375 19.725 10.267 20.5 12.5 20.5 Z M 12.5 12.5 Z',
  active:
    'M 12.5 17.5 C 12.783 17.5 13.021 17.404 13.212 17.213 C 13.404 17.021 13.5 16.784 13.5 16.5 C 13.5 16.217 13.404 15.979 13.212 15.788 C 13.021 15.596 12.783 15.5 12.5 15.5 C 12.217 15.5 11.979 15.596 11.787 15.788 C 11.596 15.979 11.5 16.217 11.5 16.5 C 11.5 16.784 11.596 17.021 11.787 17.213 C 11.979 17.404 12.217 17.5 12.5 17.5 Z M 12.5 13.5 C 12.783 13.5 13.021 13.404 13.212 13.213 C 13.404 13.021 13.5 12.784 13.5 12.5 L 13.5 8.5 C 13.5 8.217 13.404 7.979 13.212 7.788 C 13.021 7.596 12.783 7.5 12.5 7.5 C 12.217 7.5 11.979 7.596 11.787 7.788 C 11.596 7.979 11.5 8.217 11.5 8.5 L 11.5 12.5 C 11.5 12.784 11.596 13.021 11.787 13.213 C 11.979 13.404 12.217 13.5 12.5 13.5 Z M 12.5 22.5 C 11.117 22.5 9.817 22.238 8.6 21.713 C 7.383 21.188 6.325 20.475 5.425 19.575 C 4.525 18.675 3.812 17.617 3.287 16.4 C 2.762 15.184 2.5 13.884 2.5 12.5 C 2.5 11.117 2.762 9.817 3.287 8.6 C 3.812 7.384 4.525 6.325 5.425 5.425 C 6.325 4.525 7.383 3.813 8.6 3.288 C 9.817 2.763 11.117 2.5 12.5 2.5 C 13.883 2.5 15.183 2.763 16.4 3.288 C 17.617 3.813 18.675 4.525 19.575 5.425 C 20.475 6.325 21.187 7.384 21.712 8.6 C 22.237 9.817 22.5 11.117 22.5 12.5 C 22.5 13.884 22.237 15.184 21.712 16.4 C 21.187 17.617 20.475 18.675 19.575 19.575 C 18.675 20.475 17.617 21.188 16.4 21.713 C 15.183 22.238 13.883 22.5 12.5 22.5 Z',
  width: 25,
  height: 25,
};
