import { Action } from './Action/Action';
import { renderAsync } from 'docx-preview';
import { IPatch, ImageRun, PatchType, TextRun, patchDocument } from 'docx';

export type TGetDocxProps = {
  actionID?: string;
  /** ссылка на шаблон */
  templateURL: string;
  /** имя конечного файла */
  fileName: string;
  /** атрибуты для шаблона имя-значение (например {text-name1: "привет", image-name1: "DATA URL base64"}) */
  attributes: Record<string, string>;
  /** формат вывода */
  preview?: boolean;
};

/**
 * Action для получения docx файла по шаблону
 *
 * текстовые переменные в шаблоне {{text-уникальное имя}}
 * изображения-переменные в шаблоне {{image-уникальное имя}}
 *
 * в зависимости от preview показывает конечный файл или же инициирует его скачку
 */
export const getDocx: (a: TGetDocxProps) => void = async ({
  templateURL,
  attributes,
  preview,
  fileName,
}) => {
  try {
    // Получаю файл в бинарном виде

    const response = await fetch(templateURL);

    const template = await response.arrayBuffer();

    // Обрабатываю переменные для вставки в шаблон
    const patches: { [key: string]: IPatch } = {};

    for (const [key, value] of Object.entries(attributes)) {
      if (/^image/.test(key)) {
        const responseImage = await fetch(value);
        const imageBlob = await responseImage.blob();
        const imageArrayBuffer = await imageBlob.arrayBuffer();

        if (imageBlob.type === 'image/svg+xml') {
          await new Promise((res) => {
            const readerSvg = new FileReader();
            readerSvg.readAsDataURL(imageBlob);
            readerSvg.onload = (eventFile: ProgressEvent<FileReader>) => {
              const newImage = document.createElement('img');
              newImage.src = eventFile.target?.result as string;
              newImage.onload = (
                eventImage: ProgressEvent<HTMLImageElement> | Event
              ) => {
                // создаю полотно канваса
                const canvas = document.createElement('canvas');
                const svgImage = eventImage.target as HTMLImageElement;
                canvas.width = svgImage.width;
                canvas.height = svgImage.height;
                const context = canvas.getContext('2d');
                context?.drawImage(newImage, 0, 0, canvas.width, canvas.height);
                const newImageDataURL = context?.canvas.toDataURL('image/png');
                if (newImageDataURL) {
                  patches[key] = {
                    type: PatchType.PARAGRAPH,
                    children: [
                      new ImageRun({
                        data: newImageDataURL,
                        transformation: {
                          width: svgImage.width,
                          height: svgImage.height,
                        },
                      }),
                    ],
                  };
                  res(true);
                }
              };
            };
            // создаю новое изображение на основе загруженного
          });
        } else
          await new Promise((res) => {
            const newImage = document.createElement('img');
            newImage.src = value;
            newImage.onload = (
              eventImage: ProgressEvent<HTMLImageElement> | Event
            ) => {
              const currentImage = eventImage.target as HTMLImageElement;
              patches[key] = {
                type: PatchType.PARAGRAPH,
                children: [
                  new ImageRun({
                    data: imageArrayBuffer,
                    transformation: {
                      width: currentImage.width,
                      height: currentImage.height,
                    },
                  }),
                ],
              };
              res(true);
            };
          });
      }

      if (/^text/.test(key)) {
        patches[key] = {
          type: PatchType.PARAGRAPH,
          children: [new TextRun(value)],
        };
      }
    }
    // Получаю конечный файл с вставленными переменными
    const resultBuffer = await patchDocument(template, {
      patches: patches,
    });

    const resultBlob = new Blob([resultBuffer]);

    // Создаю ссылку на файл
    const link = document.createElement('a');
    link.download = `${fileName}.docx`;
    link.href = URL.createObjectURL(resultBlob);

    if (preview) {
      // создаю обертку
      const docxWrapper = document.createElement('div');
      docxWrapper.classList.add(
        'w-100',
        'p-3',
        'h-100',
        'd-flex',
        'flex-column',
        'align-items-center'
      );

      // создаю контейнер для превью
      const previewContainer = document.createElement('div');
      previewContainer.setAttribute('id', 'previewContainer');
      previewContainer.classList.add('w-100', 'p-3', 'h-100');

      // создаю кнопку для скачки файла
      const buttonDownload = document.createElement('button');
      buttonDownload.textContent = 'Скачать файл';
      buttonDownload.setAttribute('id', 'buttonDownloadDocx');

      buttonDownload.classList.add('btn', 'btn-primary', 'mb-3', 'col-4');

      docxWrapper.appendChild(buttonDownload);
      docxWrapper.appendChild(previewContainer);

      //создаю экшен модалки
      const action = new Action();
      await action.exec({
        name: 'showModal',
        argument: {
          customContent: docxWrapper.outerHTML,
          size: 'xl',
        },
      });

      //нахожу контейнер и кнопку
      const container = document.getElementById('previewContainer');
      const button = document.getElementById('buttonDownloadDocx');

      //рендерю файл и добавляю событие
      if (!container || !button) return;
      button.addEventListener('click', () => link.click());
      await renderAsync(resultBlob, container, container, {
        inWrapper: false,
        ignoreWidth: true,
        ignoreHeight: true,
      });
    } else {
      link.click();
      URL.revokeObjectURL(link.href);
    }
  } catch (error) {
    console.log('Error DocX Create');
  }
};
