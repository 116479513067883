import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { store } from '../../../Store/Store';
import { delToast, switchShowToast } from '../../../Store/Slices/UI/sliceUI';

export const ActionDeleteToast = (arg: TSiteActionProps['argument']) => {
  if (!_.has(arg, 'id')) {
    console.log('Check argument object: { id: string, timeout?: number }');
    return;
  }
  const toast = arg as { id: string; timeout?: number };
  store.dispatch(switchShowToast(toast.id));
  setTimeout(() => store.dispatch(delToast(toast.id)), toast.timeout || 300);
};
