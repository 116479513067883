import _ from 'lodash';
import { TSliceUI } from './sliceUI';

/**
 * Обновляет локальное хранилище настроек пользовательского интерфейса
 */

export const updateLocalStorage = (slice: Partial<TSliceUI>) => {
  const lsAttributesList = slice.attributesSave?.localStorage || [];
  const lsAttributes = slice.customStates
    ? _.reduce(
        slice.customStates,
        (r, v, k) => {
          if (!lsAttributesList?.find((item) => item !== k)) return { ...r };
          return { ...r, [k]: v };
        },
        {}
      )
    : undefined;
  const lsData = {
    locale: slice.locale,
    jwt: slice.jwt,
    customStates: lsAttributes,
  };
  // console.log(_.cloneDeep(lsAttributesList));
  localStorage.setItem('UIAttributes', JSON.stringify(lsData));
};
