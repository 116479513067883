/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { NavigateFunction } from 'react-router-dom';

export const ActionSetQuery = (
  arg?: TSiteActionProps['argument'],
  navigate?: NavigateFunction
) => {
  if (!arg || !_.isObject(arg)) {
    console.log('Argument must be { [param:string]: string | string[] } type');
    return;
  }

  const currentSearch: { [a: string]: string[] } | undefined = !window.location
    .search
    ? undefined
    : window.location.search
        .slice(1)
        .split('&')
        .reduce(
          (r, v) => ({ ...r, [v.split('=')[0]]: v.split('=')[1].split(';') }),
          {}
        );

  const newSearch: { [a: string]: string[] } = _.compact([
    ...Object.keys(arg),
    ...Object.keys(currentSearch || {}),
  ]).reduce((r, v) => {
    const argValue = _.get(arg, v);
    const oldValue = _.get(currentSearch, v);
    if (argValue === undefined) return { ...r, [v]: oldValue };
    const newValue = argValue
      ? _.isString(argValue)
        ? [argValue]
        : argValue
      : _.isArray(argValue)
      ? argValue.length
        ? argValue
        : undefined
      : undefined;

    if (!newValue) return { ...r };
    return { ...r, [v]: newValue };
  }, {});

  const newSearchString =
    '?' +
    Object.keys(newSearch)
      .map((key) => `${key}=${newSearch[key].join(';')}`)
      .join('&');

  const pathName = window.location.pathname;
  if (navigate) navigate(pathName + newSearchString);
  else window.location.replace(pathName + newSearchString);
};
