import { apiRequest } from '../../../Settings/api';
import { dataFetching } from '../../../Store/Actions/actionsExtra';
import { store } from '../../../Store/Store';
import { Action } from '../Action';

export const ActionFetchMe = (action: Action) => {
  store.dispatch(
    dataFetching({
      ...apiRequest.me,
      force: true,
      action,
    })
  );
};
