import { ChangeEvent, ChangeEventHandler, useContext, useEffect } from 'react';
import { FormContext } from '../CustomForm/Helpers/Contexts';
import { TCustomInputSimpleContext, TCustomInputSimple } from './types';
import _ from 'lodash';
import { CustomInputSimpleContext } from './Helpers/Contexts';
import { setInputValue } from '../CustomInputImage/Helpers/setInputValue';

export const CustomInputSimple: React.FC<TCustomInputSimple> = (props) => {
  const form = useContext(FormContext);

  useEffect(() => {
    if (props.name && form && !form.props?.[props.name]) {
      const newFormInputs = {
        ..._.cloneDeep(form.props),
        [props.name]: _.omit(props, 'children'),
      };
      form.setInputs(newFormInputs);
    }
  }, [form, props]);

  if (!form) {
    console.log(
      'Tag <InputSimpleContext> must be child of the <FormContext> tag!'
    );
    return null;
  }

  if (!props.name) {
    console.log('Check required attributes: {name: string}');
    return null;
  }

  const maskedHandle: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const regExp = new RegExp(_.toString(props.mask));
    const newValue = value
      .split('')
      .reduce((r, v) => {
        const srcString = r.join('') + v;
        if (regExp.exec(srcString)) return [...r, v];
        return [...r];
      }, [] as string[])
      .join('');
    setInputValue({
      id: props.name,
      name: props.name,
      type: props.type,
      required: props.required,
      value: newValue,
      onchange: (event) =>
        form.handleChange(event as unknown as ChangeEvent<unknown>),
    });
  };
  const context: TCustomInputSimpleContext = {
    name: props.name,
    type: props.type,
    value: form?.values[props.name],
    error: (form?.errors as Record<string, string | undefined>)[props.name],
    onChange: props.mask ? maskedHandle : form.handleChange,
  };

  return (
    <CustomInputSimpleContext.Provider value={context}>
      {props.children}
    </CustomInputSimpleContext.Provider>
  );
};
