import React from 'react';
import { TCollectionContext, TTemplateContext } from '../types';

export const TemplateContext = React.createContext<
  TTemplateContext | undefined
>(undefined);

export const CollectionContext = React.createContext<
  TCollectionContext | undefined
>(undefined);
