/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext } from 'react';
import { TTemplateElem } from '../../../TemplateElement/types';
import { CarouselSlideContext } from '../CarouselSlides/Helpers/Contexts';

export const CarouselSlideItems: React.FC<{
  itemTemplate: TTemplateElem;
}> = ({ itemTemplate }) => {
  return null;
  // const slideItems = useContext(CarouselSlideContext)?.slide || [];

  // return (
  //   <>
  //     {slideItems.map((unit, key) => (
  //       <CollectionItemContext.Provider
  //         key={key}
  //         value={{ key: key.toString(), unit }}
  //       >
  //         <TemplateElement templateItem={itemTemplate} />
  //       </CollectionItemContext.Provider>
  //     ))}
  //   </>
  // );
};
