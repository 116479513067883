import _ from 'lodash';

/**
 * Строит параметры URL на основе указанного URL и массива шаблонов.
 *
 * @param {string} srcUrl - URL, из которого извлекаются параметры.
 * @param {string[]} templates - Массив шаблонов для сопоставления с URL.
 * @return {Record<string, string>} Извлеченные параметры URL в виде пар ключ-значение.
 */
export const constructUrlParams = (
  srcUrl: string,
  templates: string[]
): Record<string, string> => {
  const urlPath = srcUrl.split('/');
  const params = _.reduce(
    templates,
    (r, v) => {
      const tempRegEx =
        '^\\/' +
        v
          ?.split('/')
          .map((item) => (/^:.+$/.exec(item) ? '.+' : item))
          .join('\\/') +
        '$';
      if (new RegExp(tempRegEx).exec(srcUrl)) {
        const paramsPos = _.reduce(
          v?.split('/'),
          (rr, vv, kk) => {
            if (/^:(.*)$/.exec(vv)) return { ...rr, [vv.replace(':', '')]: kk };
            return { ...rr };
          },
          {} as Record<string, number>
        );
        if (Object.keys(paramsPos).length) {
          return {
            ...r,
            ..._.reduce(
              paramsPos,
              (rr, vv, kk) => ({
                ...rr,
                [kk]: urlPath[vv],
              }),
              {}
            ),
          };
        } else return { ...r };
      } else {
        return { ...r };
      }
    },
    {} as Record<string, string>
  );
  return params;
};
