import { TCustomInputImage, TCustomInputImageContext } from './types';
import { ChangeEvent, useContext, useState } from 'react';
import { CustomInputImageContext } from './Helpers/Contexts';
import { FormContext } from '../CustomForm/Helpers/Contexts';
import { ImageTransform } from './Helpers/ImageTransform';
import { setInputValue } from './Helpers/setInputValue';

export const CustomInputImage: React.FC<TCustomInputImage> = ({
  name,
  formats,
  width,
  height,
  convertTo = 'jpeg',
  quality = 80,
  crop,
  children,
  formatErrorMessage,
  required,
}) => {
  const form = useContext(FormContext);
  const [error, setError] = useState<string>();
  if (!form) return null;

  const handleCleanup = () => {
    setError(undefined);
    setInputValue({
      id: name,
      name: name,
      type: 'text',
      required: !!required,
      value: '',
      onchange: (e) => form.handleChange(e as unknown as ChangeEvent<unknown>),
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);

    const { files } = event.target;

    if (!files || !files.length) return;

    const file = files[0];

    if (!formats.includes(file.type)) {
      setError(formatErrorMessage);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (eventFile: ProgressEvent<FileReader>) =>
      ImageTransform(
        name,
        eventFile,
        form.handleChange,
        width,
        height,
        !!crop,
        convertTo,
        quality
      );
  };

  const context: TCustomInputImageContext = {
    name,
    onChange: handleChange,
    onClean: handleCleanup,
    value: form.values[name],
    error: error || (form.errors as Record<string, string | undefined>)[name],
  };

  return (
    <CustomInputImageContext.Provider value={context}>
      {children}
    </CustomInputImageContext.Provider>
  );
};
