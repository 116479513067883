import { Mutator } from '../Mutator';
import { TMutationContext } from '../types';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../../Store/Store';
import { useParams } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { PageContext } from '../../../Services/Contexts';
import { FormContext } from '../../../Components/Pages/Components/Page/Components/CustomForm/Helpers/Contexts';
import { CustomToastsContext } from '../../../Components/Pages/Components/Page/Components/CustomToasts/Helpers/Contexts';
import { CarouselMainContext } from '../../../Components/Pages/Components/Page/Components/CustomCarousel/Helpers/Contexts';
import { CarouselSlideContext } from '../../../Components/Pages/Components/Page/Components/CustomCarousel/Components/CarouselSlides/Helpers/Contexts';
import {
  TemplateContext,
  CollectionContext,
} from '../../../Components/Pages/Components/Page/Components/TemplateElement/Helpers/contexts';
import { CustomInputSimpleContext } from '../../../Components/Pages/Components/Page/Components/CustomInputSimple/Helpers/Contexts';
import { CustomInputImageContext } from '../../../Components/Pages/Components/Page/Components/CustomInputImage/Helpers/Contexts';
import _ from 'lodash';
import { API_URL_PREFIX } from '../../../Settings/api';
import { getCollectionItems } from '../../../Store/Slices/Data/getCollectionItems';
import { TUser } from '../../../Types/typesUser';
import { TSiteSettings } from '../../../Types/typesStructure';
import { TUnit } from '../../../Types/typesUnit';

/**
 * Хук useMutator. Определяется контекст мутации на основе данных из store.
 * @returns экземпляр класса {@link Mutator}
 */
export const useMutator = () => {
  const params = useParams();
  const rootState = store.getState();
  const search = _.compact(
    window.location.search?.replace('?', '').split('&')
  ).reduce(
    (r, v) => ({ ...r, [v.split('=')[0]]: v.split('=')[1] }),
    {} as Record<string, string>
  );

  const users = useMemo(
    () =>
      getCollectionItems(
        'users',
        undefined,
        rootState.sliceData.collections,
        2
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootState.sliceData.collections, rootState.sliceData.fetchStatuses]
  ) as TUser[];

  const me = users.find((item) => item.id === rootState.sliceUI.meID) as
    | TUser
    | undefined;

  const units = useMemo(
    () =>
      getCollectionItems(
        'units',
        undefined,
        rootState.sliceData.collections,
        2
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootState.sliceData.collections, rootState.sliceData.fetchStatuses]
  ) as TUnit[];

  const siteSets = useMemo(
    () =>
      getCollectionItems(
        'siteSets',
        { param: 'locale', value: rootState.sliceUI.locale },
        rootState.sliceData.collections
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      rootState.sliceUI.locale,
      rootState.sliceData.collections,
      rootState.sliceData.fetchStatuses,
    ]
  )[0] as TSiteSettings | undefined;

  const page = useContext(PageContext);
  const collection = useContext(CollectionContext);
  const form = useContext(FormContext);
  const ui = useSelector((state: RootState) => state.sliceUI);
  const pathName = document.location.pathname;
  const slides = useContext(CarouselMainContext);
  const slide = useContext(CarouselSlideContext);
  const template = useContext(TemplateContext);
  const simpleInput = useContext(CustomInputSimpleContext);
  const imageInput = useContext(CustomInputImageContext);
  const toasts = useContext(CustomToastsContext);

  const context: Partial<TMutationContext> = {
    ui: {
      locale: ui.locale,
      states: ui.customStates,
    },
    me: {
      user: me,
      token: ui.jwt,
    },
    current: {
      page,
      url: {
        pathName,
        params: params,
        query: search,
        prefix: API_URL_PREFIX,
      },
      request: {},
      collection,
      form,
      input: simpleInput || imageInput,
      slideshow: {
        slides: slides?.slides,
        slide: slide?.slide,
      },
      template,
    },
    global: {
      variables: siteSets?.globalVariables,
      toasts,
      collection: {
        users: users,
        units: units,
      },
    },
  };
  return new Mutator(context as TMutationContext);
};
