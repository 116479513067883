import { useEffect } from 'react';
import { TSubmitDataState } from '../types';

type TProps = TSubmitDataState & {
  children?: React.ReactNode;
  setSubmitDataState: React.Dispatch<TSubmitDataState>;
};

export const CustomFormChildren: React.FC<TProps> = ({
  setSubmitDataState,
  children,
  values,
  errors,
  touched,
}) => {
  useEffect(() => {
    setSubmitDataState({ values, touched, errors });
  }, [errors, setSubmitDataState, touched, values]);
  return <>{children}</>;
};
