import _ from 'lodash';
import {
  TBaseDataItem,
  TBaseDataObject,
  TMutationSchema,
} from '../../../../Types/typesGlobal';

/**
 * Функция создает схему мутаций на основе предоставленного объекта хранилища.
 *
 * @param {TBaseDataObject} storeItem - Объект хранилища для создания карты мутаций
 * @return {TMutationSchema} Созданная карта мутаций
 */
export const constructMutationSchema = (
  storeItem: TBaseDataObject
): TMutationSchema => {
  /**
   * Функция создает массив атрибутов на основе переданного массива объектов или элементов данных.
   *
   * @param {TBaseDataObject[] | TBaseDataItem[] | (TBaseDataObject | TBaseDataItem)[]} array - Массив объектов или элементов данных
   * @param {string} path - Путь к текущему массиву в структуре
   * @param {string[]} [attributes] - Массив атрибутов (необязательный)
   * @return {string[]} Массив атрибутов
   */
  const constructArrayAttributes = (
    array:
      | TBaseDataObject[]
      | TBaseDataItem[]
      | (TBaseDataObject | TBaseDataItem)[],
    path: string,
    attributes?: string[]
  ): string[] => {
    return [
      ...(attributes || []),
      ..._.reduce(
        array,
        (r, v, k) => {
          if (_.isString(v) && /<%.+%>/.test(v))
            return [...r, `${path ? path + '.' : ''}[${k}]`];
          if (_.isObject(v)) {
            if (_.isArray(v))
              return [
                ...r,
                ...constructArrayAttributes(
                  v,
                  `${path ? path + '.' : ''}[${k}]`,
                  attributes
                ),
              ];
            return [
              ...r,
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              ...constructObjAttributes(
                v,
                `${path ? path + '.' : ''}[${k}]`,
                attributes
              ),
            ];
          }
          return [...r];
        },
        [] as string[]
      ),
    ];
  };

  /**
   * Функция создает массив атрибутов на основе переданного объекта данных.
   *
   * @param {TBaseDataObject} srcObj - Объект данных для создания массива атрибутов
   * @param {string} [path] - Путь к текущему объекту в структуре (необязательный)
   * @param {string[]} [attributes] - Массив атрибутов (необязательный)
   * @return {string[]} Массив атрибутов
   */
  const constructObjAttributes = (
    srcObj: TBaseDataObject,
    path?: string,
    attributes?: string[]
  ): string[] => {
    return _.reduce(
      srcObj,
      (r, v, k) => {
        if (_.isString(v) && /<%.+%>/.test(v))
          return [...r, `${path ? path + '.' : ''}${k}`];
        if (_.isObject(v)) {
          if (_.isArray(v))
            return [
              ...r,
              ...constructArrayAttributes(
                v,
                `${path ? path + '.' : ''}${k}`,
                attributes
              ),
            ];
          return [
            ...r,
            ...constructObjAttributes(
              v,
              `${path ? path + '.' : ''}${k}`,
              attributes
            ),
          ];
        }
        return [...r];
      },
      [] as string[]
    );
  };

  const attributes = constructObjAttributes(storeItem);

  const map: TMutationSchema = {
    attributes,
    stores: [], // TODO Добавить парсинг списка хранилищ, задействованных в мутациях
  };
  return map;
};
