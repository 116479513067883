import { useContext } from 'react';
import { TTemplateElem } from '../../../TemplateElement/types';
import { CarouselSlideContext } from './Helpers/Contexts';
import { CarouselMainContext } from '../../Helpers/Contexts';
import { TemplateElement } from '../../../TemplateElement/TemplateElement';

export const CarouselSlides: React.FC<{
  slideSet: number;
  slideTemplate: TTemplateElem;
}> = ({ slideSet, slideTemplate }) => {
  const slides = useContext(CarouselMainContext)?.slides || [];

  const slidesBySet = slides[slideSet];

  return (
    <>
      {slidesBySet.map((slide, key) => (
        <CarouselSlideContext.Provider value={{ slide }} key={key}>
          <TemplateElement {...slideTemplate} />
        </CarouselSlideContext.Provider>
      ))}
    </>
  );
};
