import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { NavigateFunction } from 'react-router-dom';

/**
 * Записать в state.redirectTo указаный в props.argument адрес
 */
export const ActionNavigateTo = (
  arg: TSiteActionProps['argument'],
  navigate?: NavigateFunction
) => {
  if (_.isString(arg)) {
    const isLocal = !/^.+:/.test(arg);
    if (isLocal) {
      if (navigate) navigate(arg);
      else window.location.replace(arg);
    } else {
      window.open(arg, '_blank');
    }
  } else {
    console.log('wrong argument for navigateTo action!');
  }
};
