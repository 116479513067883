import _ from 'lodash';
import { TCustomFormSchema } from '../types';
import * as yup from 'yup';

const acceptedTypes = ['string', 'number', 'boolean', 'date'];

const acceptedMids = [
  'required',
  'length',
  'min',
  'max',
  'matches',
  'email',
  'url',
  'uuid',
  'ensure',
  'trim',
  'lowercase',
  'uppercase',
  'lessThan',
  'moreThan',
  'positive',
  'negative',
  'integer',
  'truncate',
  'round',
];

export const constructSchema = (srcSchema: TCustomFormSchema) => {
  const getInputSchema = (inputSrcSchema: TCustomFormSchema['']) => {
    const inputMids = Object.keys(_.omit(inputSrcSchema, ['type']));

    const typeString = acceptedTypes.find(
      (type) => type === inputSrcSchema.type
    )
      ? '.' + inputSrcSchema.type + '()'
      : undefined;

    const convertValue = (value: string | number | boolean) => {
      switch (typeof value) {
        case 'string':
          return /^\/.*\/$/.test(value)
            ? `'${value.slice(1, -1)}'`
            : `'${value}'`;
        default:
          return value;
      }
    };

    const inputSchema = inputMids.map((midName) => {
      if (!acceptedMids.find((item) => item === midName)) return null;
      const arg =
        typeof inputSrcSchema[midName] === 'object'
          ? (inputSrcSchema[midName] as (string | number | boolean)[]).map(
              (item) => convertValue(item)
            )
          : convertValue(inputSrcSchema[midName] as string | number | boolean);

      return `.${midName}(${arg})`;
    });

    // console.log('validators' + typeString + _.compact(inputSchema).join(''));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const validators = yup;
    // eslint-disable-next-line no-eval
    return eval('validators' + typeString + _.compact(inputSchema).join(''));
  };

  const inputsSchema = _.reduce(
    srcSchema,
    (r, v, k) => ({ ...r, [k]: getInputSchema(v) }),
    {}
  );

  return yup.object().shape(inputsSchema);
};
