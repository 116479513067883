import { useContext } from 'react';
import { TemplateContext } from '../TemplateElement/Helpers/contexts';
import { TemplateElement } from '../TemplateElement/TemplateElement';
import { TChildren } from './types';
import _ from 'lodash';
import { TTemplateElem } from '../TemplateElement/types';

export const CustomChildren: React.FC<TChildren> = (props) => {
  const children = useContext(TemplateContext)?.children?.filter(
    (item) => item.group === props.group
  );
  if (!children || !children.length) return null;
  return (
    <>
      {children.map((templateElement, i) => {
        if (!props.mutationSchema?.path)
          return <TemplateElement key={i} {...templateElement} />;
        const attributesByPath = _.reduce(
          templateElement.mutationSchema?.attributes,
          (r, v) => {
            const currentPathRegExp = new RegExp(
              '/^' +
                [...(templateElement.mutationSchema?.path || []), ''].join() +
                '/'
            );
            if (currentPathRegExp.test(v)) {
              const currentPathAttribute = v
                .replace(currentPathRegExp, '')
                .replace(/^template\.(\[\d.+\]\.)?/, '');
              return [
                ...r,
                `${props.mutationSchema?.path?.join(
                  '.'
                )}.${currentPathAttribute}`,
              ];
            }
            return [...r];
          },
          [] as string[]
        );

        const mutationSchema: TTemplateElem['mutationSchema'] = {
          path: props.mutationSchema?.path,
          attributes: attributesByPath,
          stores: props.mutationSchema.stores,
        };

        return (
          <TemplateElement
            key={i}
            {...{ ...templateElement, mutationSchema }}
          />
        );
      })}
    </>
  );
};
