import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../Store/Store';
import { TCustomToasts } from './types';
import { CustomToastsContext } from './Helpers/Contexts';

export const CustomToasts: React.FC<TCustomToasts> = ({ children }) => {
  const toasts = Object.values(
    useSelector((state: RootState) => state.sliceUI.toasts)
  );
  return (
    <CustomToastsContext.Provider value={toasts}>
      {children}
    </CustomToastsContext.Provider>
  );
};
