import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { TGetQRCodeProps, getQRCode } from '../../getQRCode';

export const ActionGetQRCode = (
  arg?: TSiteActionProps['argument'],
  actionID?: string
) => {
  if (arg && _.isObject(arg) && _.has(arg, 'content') && _.has(arg, 'ID')) {
    getQRCode({ ...(arg as TGetQRCodeProps), actionID });
  } else {
    console.log('wrong argument for getDOCX action!');
  }
};
