import { schema } from 'normalizr';
import schemaNormalizeUnitTypes from './schemaNormalizeUnitTypes';
import schemaNormalizeUsers from './schemaNormalizeUsers';

/**
 * Нормализация юнитов
 *
 * @param depth - глубина нормализации
 * @returns схема
 */
const schemaNormalizeUnits = (depth?: number): schema.Entity => {
  return new schema.Entity('units', {
    type: schemaNormalizeUnitTypes(),
    ownerUnit: depth
      ? schemaNormalizeUnits(depth - 1)
      : new schema.Entity('units'),
    ownUnits: [
      depth ? schemaNormalizeUnits(depth - 1) : new schema.Entity('units'),
    ],
    memberUnits: [
      depth ? schemaNormalizeUnits(depth - 1) : new schema.Entity('units'),
    ],
    membershipUnits: [
      depth ? schemaNormalizeUnits(depth - 1) : new schema.Entity('units'),
    ],
    ownerPerson: depth
      ? schemaNormalizeUsers(depth - 1)
      : new schema.Entity('users'),
    memberPersons: [
      depth ? schemaNormalizeUsers(depth - 1) : new schema.Entity('users'),
    ],
  });
};

export default schemaNormalizeUnits;
