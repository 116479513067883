import _ from 'lodash';
import { TResponseObject } from '../../../Types/typesFetch';
import { TTemplateElem } from '../../../Components/Pages/Components/Page/Components/TemplateElement/types';

export const constructErrorMessage = (
  error: TResponseObject['error']
): TTemplateElem => {
  const errorTemplate = _.reduce(
    error,
    (r, v, k) => {
      if (!_.isString(v) && !_.isNumber(v)) return [...r];
      const term: TTemplateElem = {
        tag: 'dt',
        children: k,
      };
      const val: TTemplateElem = {
        tag: 'dd',
        children: _.toString(v),
      };
      return [...r, term, val];
    },
    [] as TTemplateElem[]
  );

  return {
    tag: 'dl',
    children: errorTemplate,
  };
};
