import { schema } from 'normalizr';

/**
 * Нормализация уведомлений
 *
 * @param depth - глубина нормализации
 * @returns схема
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaNormalizeAlerts = (depth?: number): schema.Entity => {
  return new schema.Entity('alerts');
};

export default schemaNormalizeAlerts;
