import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import sliceUI from './Slices/UI/sliceUI';
import sliceData from './Slices/Data/sliceData';
import { sagaRoot } from './Sagas/sagaRoot';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    sliceUI,
    sliceData,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.action'],
      },
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(sagaRoot);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
