import { call, take } from 'redux-saga/effects';
import { INITIAL_FETCH } from '../../Settings/extraActions';
import { workerGetLocalStorage } from '../Workers/workerGetLocalStorage';
import { workerFetchData } from '../Workers/workerDataFetch';
import { APP_INIT_QUEUE } from '../../Settings/app';
import { store } from '../Store';
import _ from 'lodash';
import { Action } from '../../Library/Action/Action';
import { Mutator } from '../../Library/Mutator/Mutator';
import { TSiteActionProps } from '../../Types/typesGlobal';
import { TSoreItemsFetchExtra } from '../../Types/typesFetch';
/**
 * Сага инициализации приложения
 *
 * Запускается при инициализации приложения. Запрашивает данные из ЛС и сервера API согласно APP_INIT_QUEUE.
 */
export function* sagaInitialWatch() {
  while (true) {
    yield take(INITIAL_FETCH);
    const action = new Action();
    // Получаю настройки из ЛС
    yield call(() => workerGetLocalStorage());

    const jwt = store.getState().sliceUI.jwt;
    // Получаю объекты согласно очереди
    for (const queueItem of APP_INIT_QUEUE) {
      // console.log(payload);
      if (!(queueItem.query.id === 'get-me' && !jwt)) {
        const payload: TSoreItemsFetchExtra = {
          ...queueItem.query,
          action,
        };
        yield call(() =>
          workerFetchData({
            payload,
            type: payload.responseModel?.sliceData?.normSchema || 'unknown',
          })
        );
      }
    }

    //TODO  Доработать после обновления Mutator
    if (jwt) {
      const meID = store.getState().sliceUI.meID;
      const meUser = meID
        ? store.getState().sliceData.collections.users[meID]
        : null;
      const siteSettings = _.find(
        store.getState().sliceData.collections.siteSets,
        (item) => item.locale === store.getState().sliceUI.locale
      );

      const userActions = meUser?.onLoadAction
        ? _.isArray(meUser.onLoadAction)
          ? meUser.onLoadAction
          : [meUser.onLoadAction]
        : [];
      const propsActions = siteSettings?.loginActions
        ? _.isArray(siteSettings.loginActions)
          ? siteSettings.loginActions
          : [siteSettings.loginActions]
        : [];

      if ([...userActions, ...propsActions].length) {
        const mutator = new Mutator();
        for (const actionProps of [...userActions, ...propsActions]) {
          const mutantActionProps = mutator.applyObjectVariables(
            actionProps
          ) as TSiteActionProps;
          yield call(() => action.exec(mutantActionProps));
        }
      }
    }
  }
}
