import { TApiQuery, TResponseObject } from '../Types/typesFetch';
import { TSiteActionProps } from '../Types/typesGlobal';

/** Адрес сервера  API */
export const API_URL_PREFIX =
  document.location.port === '3000'
    ? `http://${document.location.hostname}:8000`
    : '';

/**
 * Параметры запросов
 */
export const apiRequest: Record<string, TApiQuery> = {
  me: {
    id: 'get-me',
    url: API_URL_PREFIX + '/api/users/me',
    method: 'GET',
    populate: '*',
    responseModel: {
      sliceData: {
        normSchema: 'users',
      },
      sliceUi: {
        meID: '<% return current.request?.response?.id || null %>',
      },
    },
    onRejected: { name: 'logOut' },
  },
  loginLocal: {
    id: 'login-local',
    url: API_URL_PREFIX + '/api/auth/local',
    method: 'POST',
    responseModel: {},
  },
  registerLocal: {
    id: 'register-local',
    url: API_URL_PREFIX + '/api/auth/local/register',
    method: 'POST',
    responseModel: {},
  },
  forgotPassword: {
    id: 'forgot-password',
    url: API_URL_PREFIX + '/api/auth/forgot-password',
    method: 'POST',
    responseModel: {},
  },
  resetPassword: {
    id: 'reset-password',
    url: API_URL_PREFIX + '/api/auth/reset-password',
    method: 'POST',
    responseModel: {},
  },
  users: {
    id: 'users',
    url: API_URL_PREFIX + '/api/users',
    populate: '*',
    method: 'GET',
    responseModel: {
      sliceData: {
        normSchema: 'users',
      },
    },
  },
  units: {
    id: 'units',
    url: API_URL_PREFIX + '/api/units',
    populate: '*',
    method: 'GET',
    responseModel: {
      sliceData: {
        normSchema: 'units',
      },
    },
  },
  unitTypes: {
    id: 'unit-types',
    url: API_URL_PREFIX + '/api/unit-types',
    populate: '*',
    method: 'GET',
    responseModel: {
      sliceData: {
        normSchema: 'unitTypes',
      },
    },
  },
  siteSets: {
    id: 'site-sets',
    url: API_URL_PREFIX + '/api/site-sets',
    populate: '*',
    method: 'GET',
    responseModel: {
      sliceData: {
        normSchema: 'siteSets',
      },
    },
  },
  itemTemplates: {
    id: 'item-templates',
    url: API_URL_PREFIX + '/api/item-templates',
    populate: '*',
    method: 'GET',
    responseModel: {
      sliceData: {
        normSchema: 'itemTemplates',
      },
    },
  },
  icons: {
    id: 'icons',
    url: API_URL_PREFIX + '/api/icons',
    populate: '*',
    method: 'GET',
    responseModel: {
      sliceData: {
        normSchema: 'icons',
      },
    },
  },
  sitePages: {
    id: 'site-pages',
    url: API_URL_PREFIX + '/api/site-pages',
    populate: '*',
    method: 'GET',
    responseModel: {
      sliceData: {
        normSchema: 'sitePages',
      },
    },
  },
  mutations: {
    id: 'mutations',
    url: API_URL_PREFIX + '/api/mutations',
    populate: '*',
    method: 'GET',
    responseModel: {
      sliceData: {
        normSchema: 'mutations',
      },
    },
  },
};

/** Локаль по-умолчанию */
export const LOCALE_DEFAULT = 'ru-RU';

/** Экшны при ошибках запросов */
export const fetchErrorActions: (Partial<TResponseObject['error']> & {
  actions: TSiteActionProps[];
})[] = [
  {
    message: 'Missing or invalid credentials',
    status: 401,
    actions: [
      {
        name: 'logOut',
      },
    ],
  },
];
