import React from 'react';
import { TTemplateElem } from '../types';
import _ from 'lodash';
import { TemplateElement } from '../TemplateElement';

/**
 * Компонент для рендера дочерних элементов
 * @param props - объект с пропами
 * @param props.children - массив или объект с дочерними элементами шаблона
 * @param props.mutationSchema - схема мутации для создания элементов
 * @returns JSX-элемент
 */
export const Children: React.FC<TTemplateElem> = (props) => {
  return (
    <>
      {_.isArray(props.children) ? (
        props.children.map((child, i) =>
          _.isObject(child) ? (
            <TemplateElement
              key={i}
              {...{
                ...child,
                mutationSchema: {
                  ...props.mutationSchema,
                  path: [
                    ...(props.mutationSchema?.path || []),
                    'children',
                    `[${i}]`,
                  ],
                },
              }}
            />
          ) : (
            _.toString(child)
          )
        )
      ) : _.isObject(props.children) ? (
        <TemplateElement
          key={0}
          {...{
            ...props.children,
            mutationSchema: {
              ...props.mutationSchema,
              path: [...(props.mutationSchema?.path || []), 'children'],
            },
          }}
        />
      ) : (
        _.toString(props.children)
      )}
    </>
  );
};
