/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  TCustomForm,
  TCustomFormContext,
  TCustomFormInputs,
  TSubmitDataState,
} from './types';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { constructSchema } from './Helpers/constructSchema';
import _ from 'lodash';
import { useMutator } from '../../../../../../Library/Mutator/Hooks/useMutator';
import { FormContext } from './Helpers/Contexts';
import { useState } from 'react';
import { CustomFormChildren } from './Components/CustomFormChildren';
import { dataFetching } from '../../../../../../Store/Actions/actionsExtra';
import { TSoreItemsFetchExtra } from '../../../../../../Types/typesFetch';
import { useNavigate } from 'react-router-dom';
import { Action } from '../../../../../../Library/Action/Action';

export const CustomForm: React.FC<TCustomForm> = (props) => {
  const dispatch = useDispatch();
  const schema = props.validationSchema
    ? constructSchema(props.validationSchema)
    : undefined;
  const mutator = useMutator();
  const navigate = useNavigate();
  const action = new Action(navigate);
  const [inputs, setInputs] = useState<TCustomFormInputs>({});
  const [submitDataState, setSubmitDataState] = useState<TSubmitDataState>();
  if (!props.query?.url || !props.query?.body || !props.query.responseModel) {
    console.log('query.url, query.body and query.responseModel are required!');
    return null;
  }

  const submitHandler = () => {
    if (!props.query.id || !props.query.url) {
      console.log(
        props.query,
        'Check required attributes: {id: string, url: string}'
      );
      return;
    }

    mutator.addContext({
      current: { form: submitDataState as TCustomFormContext },
    });
    mutator.setAttrExclude(
      props.mutationProps?.attrExclude?.map((v) => new RegExp(v))
    );
    mutator.setAttrInclude(
      props.mutationProps?.attrInclude?.map((v) => new RegExp(v))
    );
    mutator.setValExclude(
      props.mutationProps?.valExclude?.map((v) => new RegExp(v))
    );
    mutator.setValInclude(
      props.mutationProps?.valInclude?.map((v) => new RegExp(v))
    );

    const submitData: TSoreItemsFetchExtra = {
      ...props.query,
      body: mutator.applyObjectVariables(props.query.body) || {},
      force: true,
      action,
    };

    if (props.test) {
      console.log(props, submitData);
    } else {
      dispatch(dataFetching(submitData));
    }
  };

  return (
    <Formik
      onSubmit={submitHandler}
      initialValues={props.initialValues || {}}
      validationSchema={schema}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => {
        const formContext: TCustomFormContext = {
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          props: inputs,
          setInputs,
        };

        return (
          <FormContext.Provider value={formContext}>
            <CustomFormChildren
              {...{
                children: props.children,
                values,
                touched,
                errors,
                setSubmitDataState,
              }}
            />
          </FormContext.Provider>
        );
      }}
    </Formik>
  );
};
