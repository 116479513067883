import _ from 'lodash';
import { TBaseDataItem } from '../../../Types/typesGlobal';
import { store } from '../../Store';
import { TSliceData } from './sliceData';
import { TDataCollectionItem } from '../../../Types/typesStructure';
import { denormalize } from 'normalizr';
import { schemaNormalize } from '../../Normalizers/schemaNormalize';

/**
 * Получить объект Юнита по значению или значениям атрибута
 *
 * @param by.param имя атрибута
 * @param by.value значения или массив значений атрибута
 * @param state Объект хранилища
 * @param path Массив ID родительских юнитов
 * @returns объект юнита
 */
export const getCollectionItems = (
  collectionName: keyof TSliceData['collections'],
  by?: { param: string; value: TBaseDataItem | TBaseDataItem[] },
  collectionsStore?: TSliceData['collections'],
  depth?: number
): TDataCollectionItem[] => {
  const collections =
    collectionsStore || store.getState().sliceData.collections;
  const collection = collections[collectionName];
  const normSchema = schemaNormalize(collectionName, true);

  if (!by) {
    const normCollection = _.map(collection, (normItem) => {
      const denormItem = normSchema
        ? denormalize(normItem, normSchema(depth), collections)
        : normItem;

      return _.reduce(
        denormItem,
        (r, v, k) => {
          if (/^fields[A-Z]/.test(k) && _.isArray(v))
            return {
              ...r,
              [k]: _.reduce(
                v,
                (rr, vv) => ({ ...rr, [vv.name || 'unknown']: vv }),
                {}
              ),
            };
          return { ...r, [k]: v };
        },
        {} as TDataCollectionItem
      );
    });
    return normCollection;
  }

  const values = _.isArray(by.value) ? by.value : [by.value];
  const normItems = _.filter(collection, (item) => {
    const itemValue = _.get(item, by.param);
    return itemValue && _.isArray(itemValue)
      ? !!_.intersection(values, itemValue).length
      : !!_.intersection(values, [itemValue]).length;
  });

  return normItems.map((normItem) =>
    normSchema
      ? denormalize(normItem, normSchema(depth), collections)
      : normItem
  );
};
