import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../Store/Store';
import React from 'react';
import { TApiQuery } from '../../../../../../../Types/typesFetch';
import { TCollectionContext } from '../types';
import _ from 'lodash';
import { Action } from '../../../../../../../Library/Action/Action';
import { execMultiplyActions } from '../../../../../../../Library/Action/Helpers/execMultiplyActions';
import { Mutator } from '../../../../../../../Library/Mutator/Mutator';

export const useFetchCollection = (
  inView: boolean,
  mutator: Mutator,
  action: Action,
  query?: TApiQuery | TApiQuery[]
): TCollectionContext => {
  const queryCollection = _.isArray(query) ? query : [query];
  const storeFetchStatus = useSelector(
    queryCollection?.[0]
      ? (state: RootState) =>
          state.sliceData.fetchStatuses[(queryCollection[0] as TApiQuery).id]
      : () => null
  );

  //TODO: Добавить пагинацию по скроллу. В storeFetchStatus есть .pagination (настройки пагинации) и .respPagination (пагинация из ответа)

  React.useEffect(() => {
    if (queryCollection[0] && inView) {
      if (!queryCollection[0].id || !queryCollection[0].url) {
        console.log(
          queryCollection[0],
          'Check required attributes: {id: string, url: string}'
        );
        return;
      }
      if (!storeFetchStatus || storeFetchStatus.status === 'initial') {
        const mutantQueryCollection = _.compact(queryCollection).map((item) =>
          mutator.applyObjectVariables(item)
        );
        execMultiplyActions(
          mutantQueryCollection.map((item) => ({
            name: 'fetch',
            argument: item,
          })),
          action,
          mutator
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFetchStatus?.status, inView]);

  if (!query || !storeFetchStatus)
    return {
      fetchStatus: 'initial',
    };

  return {
    fetchStatus: storeFetchStatus.status,
    pagination: {
      ...storeFetchStatus.pagination,
      lastPage: storeFetchStatus.pagination?.pageCount,
    },
    error: storeFetchStatus.message,
  };
};
