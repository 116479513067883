import { takeEvery } from 'redux-saga/effects';
import { dataFetching } from '../Actions/actionsExtra';
import { workerFetchData } from '../Workers/workerDataFetch';

/**
 * Сага запроса данных с сервера
 */
export function* sagaStoreItemsFetch() {
  yield takeEvery(dataFetching, workerFetchData);
}
