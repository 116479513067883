import _ from 'lodash';
import { TTemplateElem } from './types';
import { templateElements } from './Helpers/templateElements';
import { useFetchCollection } from './Hooks/useFetchCollection';
import { mutantTemplateElement } from './Helpers/mutantTemplateElement';
import { useMutator } from '../../../../../../Library/Mutator/Hooks/useMutator';
import React from 'react';
import { CollectionContext, TemplateContext } from './Helpers/contexts';
import { Action } from '../../../../../../Library/Action/Action';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { execMultiplyActions } from '../../../../../../Library/Action/Helpers/execMultiplyActions';
import { ElementCustom } from './Components/ElementCustom';
import { ElementDefault } from './Components/ElementDefault';

export const TemplateElement: React.FC<TTemplateElem> = (templateElement) => {
  const mutator = useMutator();
  const templateContext = React.useContext(TemplateContext);
  const navigate = useNavigate();
  const action = new Action(navigate);
  const mutantElement = mutantTemplateElement(
    mutator,
    templateElement,
    action,
    templateContext?.path
  );

  // Запускаю экшны при загрузке компонента
  React.useEffect(() => {
    if (mutantElement?.onLoad) {
      execMultiplyActions(
        _.isArray(mutantElement.onLoad)
          ? mutantElement.onLoad
          : [mutantElement.onLoad],
        action,
        mutator
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutantElement?.onLoad]);

  const [ref, inView] = useInView();

  const queryOnScroll = mutantElement?.query
    ? _.isArray(mutantElement.query)
      ? !!mutantElement.query[0].loadOnScroll
      : !!mutantElement.query.loadOnScroll
    : false;

  const collection = useFetchCollection(
    (mutantElement?.query && !queryOnScroll) || inView,
    mutator,
    action,
    mutantElement && mutantElement.tag !== 'FormContext'
      ? mutantElement.query
      : undefined
  );

  if (!mutantElement || mutantElement.excluded) return null;

  const customElement = _.get(templateElements, mutantElement.tag || 'unknown');

  const domElement = customElement ? (
    <ElementCustom component={customElement} templateElement={mutantElement} />
  ) : (
    <ElementDefault {...mutantElement} />
  );

  if (templateElement.query)
    return (
      <CollectionContext.Provider value={collection}>
        {domElement}
        {queryOnScroll ? <div ref={ref}></div> : null}
      </CollectionContext.Provider>
    );

  return <>{domElement}</>;
};
