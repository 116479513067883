import { TBaseDataObject } from '../../../../../../../Types/typesGlobal';
import _ from 'lodash';

export const setInputValue = (input: Partial<HTMLInputElement>) => {
  const inputObj = input as unknown as TBaseDataObject;
  const formInput = document.createElement('input');
  Object.keys(input).forEach((prop) => {
    if (/^on.+/.test(prop) && _.isFunction(inputObj[prop])) {
      const listenerName = _.lowerCase(prop.slice(1));
      const eventHandle = inputObj[prop] as unknown as (e: Event) => void;
      formInput.addEventListener(listenerName, eventHandle);
      const newEvent = new Event(listenerName);
      formInput.dispatchEvent(newEvent);
      formInput.remove();
    } else {
      (formInput as unknown as TBaseDataObject)[prop] = (
        input as unknown as TBaseDataObject
      )[prop];
    }
  });
};
