import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { TApiQuery } from '../../../Types/typesFetch';
import { store } from '../../../Store/Store';
import { dataFetching } from '../../../Store/Actions/actionsExtra';
import { Action } from '../Action';

/**
 * Запрос к API
 *
 * @param {TSiteActionProps['argument']} arg - параметры запроса
 */
export const ActionFetch = (
  arg: TSiteActionProps['argument'],
  action: Action
) => {
  if (!_.has(arg, 'url') || !_.has(arg, 'id')) {
    console.log(`
    Check argument {
      url: string;
      id: string;
      method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
      repeat?: {
        interval: number;
        counts?: number;
      };
      onSucceed?: TSiteActionProps | TSiteActionProps[];
      onRejected?: TSiteActionProps | TSiteActionProps[];
      responseModel: {
        data?: {
          normSchema: string;
          collections?: string | TBaseDataObject | TAbstractStore;
        };
        ui?: string | TBaseDataObject | TAbstractStore;
      };
      body?: TBaseDataObject;
    }`);
    return;
  }

  store.dispatch(
    dataFetching({
      ...(arg as TApiQuery),
      force: true,
      action,
    })
  );
};
