import { schema } from 'normalizr';
import schemaNormalizeAlerts from './schemaNormalizeAlerts';

/**
 * Нормализация свойств сайта
 *
 * @param depth - глубина нормализации
 * @returns схема
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaNormalizeSiteSets = (depth?: number): schema.Entity => {
  return new schema.Entity('siteSets', {
    systemAlerts: [schemaNormalizeAlerts()],
  });
};

export default schemaNormalizeSiteSets;
