import { normalize } from 'normalizr';
import { TResponseDataModel, TResponseObject } from '../../../Types/typesFetch';
import { TAbstractStore } from '../../../Types/typesGlobal';
import { schemaNormalize } from '../../Normalizers/schemaNormalize';
import { applyPermissions } from './applyPermissions';
import _ from 'lodash';
import { RootState } from '../../Store';
import { TSliceData } from '../../Slices/Data/sliceData';
import { Mutator } from '../../../Library/Mutator/Mutator';
import { constructFakeChildren } from './constructFakeChildren';

/**
 *  Сборка коллекций слайса данных из объекта ответа по данным модели
 *
 * @param responseObject - Объект ответа
 * @param dataModel - Данные модели
 * @param rootState - Состояние хранилища
 * @param mutator - Мутатор
 * @returns - Коллекции или undefined
 */
export const constructDataStoreCollections = (
  responseData: TResponseObject | TResponseObject['data'],
  dataModel: TResponseDataModel['sliceData'],
  rootState: RootState,
  mutator: Mutator
): TSliceData['collections'] | undefined => {
  if (!dataModel) return;

  const normSchema = Object.keys(rootState.sliceData.collections).includes(
    dataModel.normSchema
  )
    ? schemaNormalize(dataModel.normSchema as keyof TSliceData['collections'])
    : undefined;

  if (!normSchema) return;

  if (dataModel.collections) {
    const { mutantDataModel } = mutator.applyObjectVariables({
      mutantDataModel: dataModel.collections,
    });

    const customDataArray = _.isArray(mutantDataModel)
      ? mutantDataModel
      : [mutantDataModel];

    const normCustomCollections = _.isObject(mutantDataModel)
      ? (normalize({ [dataModel.normSchema]: customDataArray }, normSchema(10))
          .entities as TAbstractStore)
      : undefined;

    return normCustomCollections
      ? (applyPermissions(normCustomCollections) as unknown as
          | TSliceData['collections']
          | undefined)
      : undefined;
  }

  const dataArray = _.compact(
    _.isArray(responseData) ? responseData : [responseData]
  );
  const fakedChildren = /^(users)|(units)$/.test(dataModel.normSchema)
    ? _.map(dataArray, (item) => constructFakeChildren(item))
    : dataArray;
  const dataNormalized = normalize(
    { [dataModel.normSchema]: fakedChildren },
    normSchema(10)
  ).entities as TAbstractStore;

  return applyPermissions(dataNormalized) as unknown as
    | TSliceData['collections']
    | undefined;
};
