import { TFetchStatus } from '../../Types/typesGlobal';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/Store';
import { DEFAULT_PAGES } from '../../Settings/template';
import { getCollectionItems } from '../../Store/Slices/Data/getCollectionItems';
import { TSliceData } from '../../Store/Slices/Data/sliceData';
import { Page } from './Components/Page/Page';
import { TSitePage } from '../../Types/typesStructure';

type TProps = {
  id: string;
  appStatus: TFetchStatus;
};

export const Pages: React.FC<TProps> = ({ id, appStatus }) => {
  const page = useSelector((state: RootState) => {
    if (appStatus !== 'success')
      return DEFAULT_PAGES[id] || DEFAULT_PAGES.error404;
    return (
      (getCollectionItems('sitePages', { param: 'id', value: id }, {
        sitePages: state.sliceData.collections.sitePages,
        itemTemplates: state.sliceData.collections.itemTemplates,
      } as TSliceData['collections'])[0] as TSitePage) || DEFAULT_PAGES.error404
    );
  });
  return <Page {...page} />;
};
