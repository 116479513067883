import { Icon } from '@iconify/react';
import _ from 'lodash';
import { store } from '../../../../../../Store/Store';
import { getIcon } from './Helpers/getIcon';
import { defaultIcon } from './Helpers/defaultIcon';
import { TCustomIcon, TIcon } from './types';
import { getCollectionItems } from '../../../../../../Store/Slices/Data/getCollectionItems';
import { useMemo } from 'react';

/**
 * Компонент иконки
 *
 * @param props - Свойства компонента
 * @returns иконка
 */
export const CustomIcon: React.FC<TCustomIcon> = (props) => {
  const rootState = store.getState();
  const icon =
    (useMemo(
      () =>
        getCollectionItems(
          'icons',
          { param: 'name', value: props.icon },
          rootState.sliceData.collections
        ),
      [props.icon, rootState.sliceData.collections]
    )[0] as TIcon | undefined) || defaultIcon;

  const iconProps = {
    ..._.omit(props, ['icon', 'active']),
    icon: getIcon(icon, props.active),
  };
  return <Icon {...iconProps} />;
};
