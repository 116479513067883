import _ from 'lodash';
import { TResponseObject } from '../../../Types/typesFetch';
import { TRequestProps, fetchApi } from './fetchApi';

export const fetchNextPages = async ({
  firstRespObj,
  url,
  method,
  parameters,
  body,
  headers,
}: TRequestProps & {
  firstRespObj: TResponseObject;
}): Promise<TResponseObject> => {
  if (
    parameters?.pagination ||
    !firstRespObj.meta?.pagination?.page ||
    !firstRespObj.meta?.pagination?.pageCount ||
    !firstRespObj.meta?.pagination?.pageSize
  )
    return firstRespObj;

  let multiRespObj = firstRespObj;
  while (
    firstRespObj.meta.pagination.page < firstRespObj.meta.pagination.pageCount
  ) {
    const nextRespObj: TResponseObject = await fetchApi({
      url,
      method,
      parameters: {
        ...parameters,
        pagination: {
          page: firstRespObj.meta.pagination.page + 1,
          pageSize: firstRespObj.meta.pagination.pageSize,
        },
      },
      body,
      headers,
    });
    multiRespObj = {
      data: [...(multiRespObj.data as []), ...(nextRespObj.data as [])],
      meta: _.merge(multiRespObj.meta, nextRespObj.meta),
      error:
        multiRespObj.error || nextRespObj.error
          ? _.merge(multiRespObj.error, nextRespObj.error)
          : undefined,
    };
  }
  return multiRespObj;
};
