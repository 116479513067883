import { IconifyIcon } from '@iconify/react';
import { TIcon } from '../types';

export const getIcon = (icon: TIcon, hover?: boolean): IconifyIcon => {
  const path = hover ? icon.active || icon.normal : icon.normal;
  return {
    body: `<path fill="currentcolor" d="${path}" />`,
    width: icon.width,
    height: icon.height,
  };
};
