import _ from 'lodash';
import { Attributes, createElement } from 'react';
import { TTemplateElem } from '../types';
import { DOM_CREATOR_EXCLUDE_ATTRIBUTES, VOID_ELEMENTS } from '../constants';
import { Children } from './Children';

/**
 * Компонент для рендера пользовательских элементов макета.
 * @param component Компонент, который будет использован для рендера элемента.
 * @param templateElement Элемент макета, который нужно отобразить.
 * @returns JSX-элемент, который будет отображен на странице.
 */
export const ElementCustom: React.FC<{
  component: React.FC;
  templateElement: TTemplateElem;
}> = ({ component, templateElement }) => {
  const elementProps = {
    ..._.omit(templateElement, DOM_CREATOR_EXCLUDE_ATTRIBUTES.CUSTOM),
    prototype:
      templateElement.tag === 'Template'
        ? templateElement.children || null
        : null,
  } as Attributes;

  try {
    return createElement(
      component,
      elementProps,
      !VOID_ELEMENTS.includes(templateElement.tag) ? (
        <Children {...templateElement} />
      ) : undefined
    );
  } catch {
    console.log(
      `Проверьте элемент макета с тэгом ${templateElement.tag} и ID ${templateElement.id}`
    );
  }
  return null;
};
