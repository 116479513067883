import _ from 'lodash';
import { Mutator } from '../../../../../../../Library/Mutator/Mutator';
import { TTemplateElem } from '../types';

export const mutantTextChildren = (
  mutator: Mutator,
  children?: TTemplateElem['children']
) => {
  mutator.setAttrExclude();
  return _.isArray(children)
    ? children.map((child) =>
        _.isString(child) ? mutator.applyStringVariables(child) : child
      )
    : _.isString(children)
    ? mutator.applyStringVariables(children)
    : children;
};
