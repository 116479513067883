import { schema } from 'normalizr';
import schemaNormalizeRoles from './schemaNormalizeRoles';
import schemaNormalizeSitePages from './schemaNormalizeSitePages';
import schemaNormalizeSiteSets from './schemaNormalizeSiteSets';
import schemaNormalizeUnitTypes from './schemaNormalizeUnitTypes';
import schemaNormalizeUnits from './schemaNormalizeUnits';
import schemaNormalizeUsers from './schemaNormalizeUsers';
import schemaNormalizeItemTemplates from './schemaNormalizeItemTemplates';
import schemaNormalizeIcons from './schemaNormalizeIcons';
import { TSliceData } from '../Slices/Data/sliceData';
import schemaNormalizeAlerts from './schemaNormalizeAlerts';

export const schemaNormalize = (
  name: keyof TSliceData['collections'],
  single?: boolean
): ((depth?: number) => schema.Entity<any> | schema.Array<any>) | undefined => {
  try {
    const schemas: Record<
      keyof TSliceData['collections'],
      () => schema.Entity
    > = {
      roles: schemaNormalizeRoles,
      sitePages: schemaNormalizeSitePages,
      siteSets: schemaNormalizeSiteSets,
      unitTypes: schemaNormalizeUnitTypes,
      units: schemaNormalizeUnits,
      users: schemaNormalizeUsers,
      itemTemplates: schemaNormalizeItemTemplates,
      icons: schemaNormalizeIcons,
      alerts: schemaNormalizeAlerts,
    };
    const singleSchema = schemas[name] as () => schema.Entity;
    return single ? singleSchema : () => new schema.Array([singleSchema()]);
  } catch {
    console.log('Normalizer not found');
    return;
  }
};
