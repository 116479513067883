import { FormGroupsContext } from './Helpers/Contexts';
import { TCustomFormGroups, TCustomFormGroupsContext } from './types';
import { useContext, useState } from 'react';

export const CustomFormGroups: React.FC<TCustomFormGroups> = ({
  minNum = 1,
  maxNum = 1,
  name,
  children,
}) => {
  const formGroups = useContext(FormGroupsContext);
  const [currentQuantity, setCurrentQuantity] = useState<number>(
    formGroups?.quantity || 0
  );

  const addHandler = () => {
    if (currentQuantity < maxNum) {
      setCurrentQuantity((prev) => prev + 1);
    }
  };

  const delHandler = () => {
    if (currentQuantity > minNum) {
      setCurrentQuantity((prev) => prev - 1);
    }
  };

  const contextValue: TCustomFormGroupsContext = {
    max: minNum,
    min: maxNum,
    name,
    quantity: currentQuantity,
    addHandler,
    delHandler,
  };

  return (
    <FormGroupsContext.Provider value={contextValue}>
      {children}
    </FormGroupsContext.Provider>
  );
};
