import { RouteObject, ScrollRestoration } from 'react-router-dom';
import { TSitePage, TSiteSettings } from '../Types/typesStructure';
import { TFetchStatus } from '../Types/typesGlobal';
import { Pages } from '../Components/Pages/Pages';
import { DEFAULT_PAGES } from '../Settings/template';

/**
 * Функция конструирует массив маршрутов для React-роутера
 *
 * @param { Record<string, TSitePage>} storePages - массив страниц из хранилища
 * @param {TFetchStatus} appStatus - статус загрузки данных
 * @param {TSiteSettings} siteSettings - настройки сайта
 * @returns {RouteObject[]} массив маршрутов
 */
export const constructRoutes = (
  storePages: Record<string, TSitePage>,
  appStatus: TFetchStatus,
  siteSettings?: TSiteSettings
): RouteObject[] => {
  const unknownPageUrl = siteSettings?.errorPages?.['404'];
  const unknownPage = unknownPageUrl
    ? Object.values(storePages).find((page) => page.url === unknownPageUrl) ||
      DEFAULT_PAGES.ERROR_404
    : DEFAULT_PAGES.ERROR_404;

  switch (appStatus) {
    case 'error':
      return [
        { path: '*', element: <Pages appStatus={appStatus} id="error500" /> },
      ];
    case 'success':
      return [
        ...Object.values(storePages).map((page) => ({
          path: page.url,
          element: (
            <>
              <Pages appStatus={appStatus} id={page.id} />
              <ScrollRestoration />
            </>
          ),
        })),
        {
          path: '*',
          element: <Pages appStatus={appStatus} id={unknownPage?.id} />,
        },
      ];
    case 'progress':
    default:
      return [
        { path: '*', element: <Pages appStatus={appStatus} id="loading" /> },
      ];
  }
};
