import _ from 'lodash';
import { setCustomState } from '../../../Store/Slices/UI/sliceUI';
import { store } from '../../../Store/Store';
import { TBaseDataObject } from '../../../Types/typesGlobal';

type TArgument = {
  name: string;
  timeout?: number;
};

export const ActionSwitchBooleanCustomState = (
  arg?: string | number | boolean | TBaseDataObject
) => {
  if (!_.has(arg, ['name'])) {
    console.log('Check argument format { name: string, }');
    return;
  }
  const { name } = arg as TArgument;
  const currentState = store.getState().sliceUI.customStates[name];
  if (!currentState) store.dispatch(setCustomState({ name, value: true }));
  if (currentState && !_.isBoolean(currentState)) {
    console.log(`State ${name} - is not boolean!`);
    return;
  }
  store.dispatch(setCustomState({ name, value: !currentState }));
};
