import _ from 'lodash';
import { TBaseDataObject } from '../../../Types/typesGlobal';

/**
 * Исправляет тип ID в объекте и массиве объектов (числовой ID в строковый)
 * Используется в запросах
 * @param source - исходные данные
 * @returns - исправленные данные
 */
export const fixDataItemIDs = (
  dataObj: TBaseDataObject | TBaseDataObject[]
): TBaseDataObject | TBaseDataObject[] => {
  /**
   * Исправляет тип ID в объекте
   *
   * @param singleObj - исходный объект
   * @returns - исправленный объект
   */
  const fixSingleObj = (singleObj: TBaseDataObject) => {
    return _.reduce(
      singleObj,
      (r, v, k) => {
        if (k === 'id' && _.isNumber(v)) return { ...r, [k]: _.toString(v) };
        if (_.isObject(v)) {
          if (!_.isArray(v))
            return { ...r, [k]: fixDataItemIDs(v) as TBaseDataObject };
          return {
            ...r,
            [k]: v.map((item) =>
              _.isObject(item) && !_.isArray(item)
                ? (fixDataItemIDs(item) as TBaseDataObject)
                : item
            ),
          };
        }
        return { ...r, [k]: v };
      },
      singleObj
    );
  };
  return _.isArray(dataObj) ? dataObj.map(fixSingleObj) : fixSingleObj(dataObj);
};
