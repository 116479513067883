import _ from 'lodash';
import { TResponseObject } from '../../../Types/typesFetch';
import { TBaseDataObject, TBaseStoreObject } from '../../../Types/typesGlobal';

/**
 * Конструктор дочерних объектов для объектов по их ID
 *
 * @param respData - данные запроса или объект из хранилища
 *
 * @return Объект с детей
 */
export const constructFakeChildren = (
  respData: TResponseObject | (TBaseStoreObject & TBaseDataObject)
): Record<string, unknown> =>
  _.reduce(
    respData,
    (r, v, k) => {
      if (/(Persons?)|(Units?)$/.test(k)) {
        if (_.isArray(v)) {
          return {
            ...r,
            [k]: v.map((item) => (_.isString(item) ? { id: item } : item)),
          };
        }
        return { ...r, [k]: _.isString(v) ? { id: v } : v };
      }
      return { ...r, [k]: v };
    },
    {}
  );
