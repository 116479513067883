import QRCode from 'qrcode';
import { Action } from './Action/Action';

export type TGetQRCodeProps = {
  actionID?: string;
  content: string;
  preview?: boolean;
};

/**
 * Получение QR кода в указанном формате вывода:
 - inlineSVG - html-элемент
 - base64 - двоичный формат данных (с указанием data:{type})
 - file - инициируется загрузка файла в формате SVG
 *
 * @example
 * // Получить объекты типа blob и arrayBuffer можно следующим образом из inlineSVG:
 * ```ts
 * const blob = new Blob([inlineSVG], { type: 'image/svg+xml' });
 * const arrayBuffer = await blob.arrayBuffer();
 * ```
 * @param content ссылка или тело QR-кода
 * @param outputFormat формат вывода QR-кода ('inlineSVG' | 'base64' | 'file')
 * @returns в sliceUI помещается строка inlineSVG | base64 
 * или инициируется загрузка файла в формате SVG (в sliceUI поместится null)
 */
export const getQRCode = async ({ content, preview }: TGetQRCodeProps) => {
  const inlineSVG = await QRCode.toString(content);

  if (preview) {
    const action = new Action(); //TODO Исправить отображение
    action.exec({
      name: 'showModal',
      argument: {
        customContent: inlineSVG,
      },
    });
  } else {
    const blob = new Blob([inlineSVG], { type: 'image/svg+xml' });
    const link = document.createElement('a');
    link.download = `qrcode.svg`;
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  }
};
