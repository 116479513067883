import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  TBaseDataItem,
  TBaseDataObject,
  TToast,
} from '../../../Types/typesGlobal';
import {
  lsDataFetchingSucceed,
  setInitialState,
  dataFetchingSucceed,
} from '../../Actions/actionsExtra';
import _ from 'lodash';
import { LOCALE_DEFAULT } from '../../../Settings/api';
import { TSiteSettings } from '../../../Types/typesStructure';
import { updateLocalStorage } from './updateLocalStorage';

/**
 * Определяет объект хранилища состояний пользовательского интерфейса.
 */
export type TSliceUI = {
  locale: string;
  meID: string | null;
  jwt: string | null;
  customStates: Record<
    string,
    TBaseDataItem | TBaseDataItem[] | TBaseDataObject | TBaseDataObject[]
  >;
  attributesSave: TSiteSettings['UIAttributesSave'];
  toasts: Record<string, TToast & { show?: boolean }>;
};

const initialState: TSliceUI = {
  meID: null,
  jwt: null,
  locale: LOCALE_DEFAULT,
  customStates: {},
  attributesSave: {},
  toasts: {},
};

/**
 * Слайс состояний пользовательского интерфейса
 */
const sliceUI = createSlice({
  name: 'UI',
  initialState,

  /**
   * Методы для изменения состояний
   * @param builder - слайс данных
   */
  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);

    builder.addCase(lsDataFetchingSucceed, (state, { payload }) => {
      if (payload) {
        const payloadKeys = Object.keys(_.omit(payload, ['me']));
        payloadKeys.forEach((key) => {
          const value = _.get(payload, key);
          _.set(state, key, value);
        });
      }
    });

    builder.addCase(dataFetchingSucceed, (state, { payload }) => {
      if (payload.response?.ui) {
        _.forEach(payload.response.ui, (v, k) => {
          if (_.isObject(v)) {
            _.merge(state[k as keyof TSliceUI], v);
          } else _.set(state, k, v);
        });
        updateLocalStorage(state);
      }
    });
  },
  reducers: {
    /**
     * Устанавливает значение локали
     * @param state - текущее состояние слайса
     * @param payload - значение локали
     */
    setLocale: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      state.locale = payload;
      updateLocalStorage(state);
    },

    /**
     * Устанавливает ID пользователя
     * @param state - текущее состояние слайса
     * @param payload - ID пользователя
     */
    setMeID: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      state.meID = payload;
    },

    /**
     * Очистка JWT токена из локального хранилища
     * @param state - текущее состояние слайса
     */
    clearJWT: (state: TSliceUI) => {
      state.jwt = null;
      updateLocalStorage({ ...state, jwt: undefined });
    },

    /**
     * Устанавливает кастомное состояние пользовательского интерфейса
     * @param state - текущее состояние слайса
     * @param payload - имя кастомного состояния и значение
     */
    setCustomState: (
      state,
      {
        payload,
      }: PayloadAction<{
        name: string;
        value?: TSliceUI['customStates'][''];
      }>
    ) => {
      _.set(state.customStates, payload.name, payload.value);
      updateLocalStorage(state);
    },

    /**
     * Добавляет объект всплывающего уведомления
     * @param state - текущее состояние слайса
     * @param payload - объект уведомления
     */
    setToast: (state: TSliceUI, { payload }: PayloadAction<TToast>) => {
      _.set(state.toasts, payload.id, { ...payload, show: false });
    },

    /**
     * Скрывает/показывает всплывающее уведомление
     * @param state - текущее состояние слайса
     * @param payload - id всплывающего уведомления
     */
    switchShowToast: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      const toast = state.toasts[payload];
      if (toast) {
        if (toast.show === undefined) {
          toast.show = true;
        } else {
          toast.show = !toast.show;
        }
      }
    },

    /**
     * Удаляет всплывающее уведомление
     * @param state - текущее состояние слайса
     * @param payload - id всплывающего уведомления
     */
    delToast: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      if (state.toasts[payload]) delete state.toasts[payload];
    },
  },
});

export const {
  setLocale,
  setMeID,
  clearJWT,
  setCustomState,
  setToast,
  switchShowToast,
  delToast,
} = sliceUI.actions;
export default sliceUI.reducer;
