import { createSlice } from '@reduxjs/toolkit';
import { TBaseDataObject, TFetchStatus } from '../../../Types/typesGlobal';
import { TUnitNorm, TUnitType } from '../../../Types/typesUnit';
import { TIcon } from '../../../Components/Pages/Components/Page/Components/CustomIcon/types';
import {
  TAlert,
  TSitePage,
  TSiteSettings,
  TTemplate,
} from '../../../Types/typesStructure';
import { TTemplateElem } from '../../../Components/Pages/Components/Page/Components/TemplateElement/types';
import { TUserNorm, TUserRole } from '../../../Types/typesUser';
import {
  setInitialState,
  dataFetchingPending,
  dataFetchingRejected,
  dataFetchingSucceed,
} from '../../Actions/actionsExtra';
import { TPagination } from '../../../Types/typesFetch';
import _ from 'lodash';
import { constructMutationSchema } from './Helpers/constructMutationMap';

/**
 * Определяет объект с тремя свойствами: status, pagination (пагинация), и message (сообщение).
 * Свойство status имеет тип TFetchStatus, pagination имеет тип TPagination и message имеет тип TTemplateItem.
 */
export type TStoreFetchStatus = {
  id: string;
  status: TFetchStatus;
  message?: TTemplateElem;
  pagination?: TPagination;
};

/**
 * Определяет объект с тремя свойствами: fetchStatuses, collections.
 * Свойство fetchStatuses имеет тип TSliceDataFetchStatuses и collections имеет тип TSliceDataCollections.
 */
export type TSliceData = {
  fetchStatuses: Record<string, TStoreFetchStatus>;
  collections: {
    siteSets: Record<string, TSiteSettings>;
    sitePages: Record<string, TSitePage>;
    itemTemplates: Record<string, TTemplate>;
    icons: Record<string, TIcon>;
    alerts: Record<string, TAlert>;
    users: Record<string, TUserNorm>;
    roles: Record<string, TUserRole>;
    units: Record<string, TUnitNorm>;
    unitTypes: Record<string, TUnitType>;
  };
};

/**
 * Создание и инициализация начального состояния
 */
const initialState: TSliceData = {
  fetchStatuses: {},
  collections: {
    siteSets: {},
    sitePages: {},
    itemTemplates: {},
    icons: {},
    alerts: {},
    users: {},
    roles: {},
    units: {},
    unitTypes: {},
  },
};

/**
 * Создание слайса
 * @param {TSliceData} initialState - начальное состояние
 * @returns слайс данных
 *
 */
const sliceData = createSlice({
  name: 'data',
  initialState,

  /**
   * Методы для изменения состояний
   *
   * @param builder - слайс данных
   */
  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);

    builder.addCase(dataFetchingPending, (state, { payload }) => {
      const status: TStoreFetchStatus = {
        id: payload.id,
        status: 'progress',
      };
      _.set(state.fetchStatuses, payload.id, status);
    });

    builder.addCase(dataFetchingRejected, (state, { payload }) => {
      const status: TStoreFetchStatus = {
        id: payload.id,
        status: 'error',
        message: payload.message,
      };
      _.set(state.fetchStatuses, payload.id, status);
    });

    builder.addCase(dataFetchingSucceed, (state, { payload }) => {
      if (payload.response?.data) {
        _.forEach(payload.response.data, (v, k) => {
          const newCollection = _.reduce(
            v,
            (rr, vv, kk) => ({
              ...rr,
              [kk]: { ...vv, mutationSchema: constructMutationSchema(vv) },
            }),
            {}
          );
          let storeCollection = (state.collections as TBaseDataObject)[k];
          if (storeCollection) {
            storeCollection = _.merge(storeCollection, newCollection);
          } else {
            _.set(state.collections, k, newCollection);
          }
        });
      }

      const status: TStoreFetchStatus = {
        id: payload.id,
        status: 'success',
        message: payload.message,
        pagination: payload.respPagination,
      };

      _.set(state.fetchStatuses, payload.id, status);
    });
  },
  reducers: {},
});

// eslint-disable-next-line no-empty-pattern
export const {} = sliceData.actions;
export default sliceData.reducer;
