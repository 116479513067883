import { TApiQuery } from '../Types/typesFetch';
import { apiRequest } from './api';

/** Очередь запросов при инициализации */

export const APP_INIT_QUEUE: { query: TApiQuery; required?: boolean }[] = [
  { query: apiRequest.icons, required: true },
  { query: apiRequest.itemTemplates, required: true },
  { query: apiRequest.unitTypes, required: true },
  { query: apiRequest.siteSets, required: true },
  { query: apiRequest.me, required: false },
  { query: apiRequest.sitePages, required: true },
];
