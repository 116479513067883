import { createElement } from 'react';
import { TTemplateElem } from '../types';
import _ from 'lodash';
import { DOM_CREATOR_EXCLUDE_ATTRIBUTES, VOID_ELEMENTS } from '../constants';
import { Children } from './Children';

/**
 * Компонент-обертка для элемента макета.
 * Выполняет рендер элемента с тегом, свойствами и дочерними элементами.
 *
 * @param props - свойства элемента макета
 * @returns JSX-элемент
 */
export const ElementDefault: React.FC<TTemplateElem> = (props) => {
  try {
    return createElement(
      props.tag,
      _.omit(props, DOM_CREATOR_EXCLUDE_ATTRIBUTES.DEFAULT),
      !VOID_ELEMENTS.includes(props.tag) ? <Children {...props} /> : undefined
    );
  } catch {
    console.log(
      `Проверьте элемент макета с тегом ${props.tag} и идентификатором ${props.id}`
    );
  }
  return null;
};
