import _ from 'lodash';
import { call, put } from 'redux-saga/effects';
import { fetchErrorActions } from '../../Settings/api';
import { TQueryParams, TResponseObject } from '../../Types/typesFetch';
import {
  dataFetching,
  dataFetchingPending,
  dataFetchingRejected,
  dataFetchingSucceed,
} from '../Actions/actionsExtra';
import { fetchApi } from './Helpers/fetchApi';
import { constructErrorMessage } from '../Slices/Data/constructErrorMessage';
import { store } from '../Store';
import { constructDataStoreCollections } from './Helpers/constructDataStoreCollections';
import { Mutator } from '../../Library/Mutator/Mutator';
import { constructUiCollections } from './Helpers/constructUiCollections';
import { fetchNextPages } from './Helpers/fetchNextPages';
import { fixDataItemIDs } from './Helpers/fixDataItemIDs';
import { TAbstractStoreObject } from '../../Types/typesGlobal';
import { execMultiplyActions } from '../../Library/Action/Helpers/execMultiplyActions';
import { Action } from '../../Library/Action/Action';

/**
 * Запрос данных с сервера
 *
 * @param payload - параметры запроса
 */
export function* workerFetchData({ payload }: ReturnType<typeof dataFetching>) {
  const {
    id,
    url,
    method,
    filters,
    populate,
    fields,
    locale,
    pagination,
    sort,
    publicationState,
    onSucceed,
    onRejected,
    responseModel,
    force,
    body,
  } = payload;

  const rootState = store.getState();
  const fetchStatus = rootState.sliceData.fetchStatuses[id]?.status;
  const action = new Action();
  if (fetchStatus === 'progress' || (fetchStatus === 'success' && !force))
    return;

  yield put(dataFetchingPending(payload));

  const parameters: TQueryParams = {
    filters,
    populate,
    fields,
    locale,
    pagination,
    sort,
    publicationState,
  };

  const headers = body
    ? {
        'Content-Type': 'application/json',
      }
    : undefined;

  const firstRespObj: TResponseObject = yield call(() =>
    fetchApi({
      url,
      method,
      parameters,
      body,
      headers: body
        ? {
            'Content-Type': 'application/json',
          }
        : undefined,
    })
  );

  const responseObject: TResponseObject = yield call(() =>
    fetchNextPages({
      firstRespObj,
      url,
      method,
      parameters,
      body,
      headers,
    })
  );

  // if (responseModel.sliceData?.normSchema === 'itemTemplates')
  //   console.log(responseObject);

  const mutator = new Mutator();
  mutator.addContext({ current: { request: { response: responseObject } } });

  if (responseObject.error) {
    yield put(
      dataFetchingRejected({
        ...payload,
        message: constructErrorMessage(responseObject.error),
      })
    );

    const defaultErrorActions =
      fetchErrorActions.find(
        (item) =>
          item.status === responseObject.error?.status &&
          item.message === responseObject.error?.message
      )?.actions || [];

    const payloadErrorActions = onRejected
      ? _.isArray(onRejected)
        ? onRejected
        : [onRejected]
      : [];

    execMultiplyActions(
      [...defaultErrorActions, ...payloadErrorActions],
      action,
      mutator
    );
  } else {
    const responseData = responseObject.data
      ? responseObject.data
      : responseObject;

    const fixedIDsData = fixDataItemIDs(responseData) as
      | TAbstractStoreObject
      | TAbstractStoreObject[];

    mutator.addContext({
      current: {
        request: {
          response: responseObject.data
            ? { ...responseObject, data: fixedIDsData }
            : (fixedIDsData as TResponseObject),
        },
      },
    });

    const dataCollections = constructDataStoreCollections(
      fixedIDsData,
      responseModel?.sliceData,
      rootState,
      mutator
    );
    const uiCollections = constructUiCollections(
      responseModel?.sliceUi,
      mutator
    );

    yield put(
      dataFetchingSucceed({
        ...payload,
        response: {
          data: dataCollections,
          ui: uiCollections,
        },
        respPagination: responseObject.meta?.pagination,
      })
    );

    const payloadSucceedActions = onSucceed
      ? _.isArray(onSucceed)
        ? onSucceed
        : [onSucceed]
      : [];

    execMultiplyActions(payloadSucceedActions, action, mutator);
  }
}
