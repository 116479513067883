import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { TRequestMethod } from '../../../Types/typesFetch';

/**
 * Создание Webhook
 */
export const ActionWebhook = (props: TSiteActionProps['argument']) => {
  if (_.isObject(props) && _.isString(props.url)) {
    fetch(props.url, {
      method: _.isString(props.method)
        ? (props.method as TRequestMethod)
        : 'GET',
      headers: _.isObject(props.headers)
        ? (props.headers as { [a: string]: string })
        : undefined,
      body: _.isObject(props.headers) ? JSON.stringify(props.body) : undefined,
    });
  }
};
